export const DataTestUtil = {
  /**
   * Takes any string value and converts it to all uppercase Alphanumeric and underscores
   * anything else a default value is returned.
   *
   * The the default value should not be returned... ever!
   */

  format(dataTestValue, spacer = false) {
    const defaultValue = 'Data_Test_Id_Goes_Here';
    const validTypes = { string: true, number: true };
    if (!validTypes[typeof dataTestValue]) return defaultValue;

    const reg = /[^\w]+/g;
    const simpleStrings = dataTestValue
      .toString()
      .trim()
      .toUpperCase()
      .replace(reg, spacer ? '_' : '');

    return simpleStrings || defaultValue;
  },
};
