import moment from 'moment';

const ONE_DAY = 24 * 60 * 60 * 1000;

const defaultDateFormat = 'YYYY-MM-DD';

const getFormattedDateString = m => m.utc().format(defaultDateFormat);

const getFormattedDateStringFromString = s => moment.utc(s);

const getTodayMoment = () => moment.utc();
const getLastWeekMoment = () => moment.utc().subtract(1, 'weeks');
const getNextWeekMoment = () => moment.utc().add(1, 'weeks');
const getLastYearMoment = () => moment.utc().subtract(1, 'year');
const getNextYearMoment = () => moment.utc().add(1, 'year');
const getDaysBeforeNowMoment = days => moment.utc().subtract(days, 'days');
const getYesterdayMoment = () => getDaysBeforeNowMoment(1);
const getDayBeforeYesterdayMoment = () => getDaysBeforeNowMoment(2);
const getDaysAfterNowMoment = days => moment.utc().add(days, 'days');
const getTomorrowMoment = () => getDaysAfterNowMoment(1);
const getDayAfterTomorrowMoment = () => getDaysAfterNowMoment(2);

const getDaysBeforeNowDateString = days =>
  getFormattedDateString(getDaysBeforeNowMoment(days));
const getDaysAfterNowDateString = days =>
  getFormattedDateString(getDaysAfterNowMoment(days));

const getDateObject = m => m.utc().toDate();

const getTodayDateString = () => getFormattedDateString(getTodayMoment());

export const DateUtils = {
  getDayOfWeek: () => {
    return new Date().getDay();
  },

  // credit to https://stackoverflow.com/a/17727953/1061011
  getDaysBetween: (dateOne, dateTwo) => {
    const dayOfFirstDate = Date.UTC(
      dateOne.getFullYear(),
      dateOne.getMonth(),
      dateOne.getDate()
    );

    const dayOfSecondDate = Date.UTC(
      dateTwo.getFullYear(),
      dateTwo.getMonth(),
      dateTwo.getDate()
    );

    return Math.abs(dayOfFirstDate - dayOfSecondDate) / ONE_DAY;
  },

  // https://stackoverflow.com/a/2536445
  getTotalMonthsBetween: (startDate, endDate) => {
    if (typeof startDate === 'string') {
      /* eslint-disable no-param-reassign */
      startDate = new Date(startDate);
    }
    if (typeof endDate === 'string') {
      /* eslint-disable no-param-reassign */
      endDate = new Date(endDate);
    }
    const endDateMoment = moment(endDate);
    const startDateMoment = moment(startDate);

    const totalMonthsBetween = Math.abs(
      endDateMoment.diff(startDateMoment, 'months')
    );

    /**
     * Business ask is to do .5 if days go over a threshold
     */
    const adjustedStartDateMoment = moment(startDate).add(
      totalMonthsBetween,
      'months'
    );
    const differenceInDays = Math.abs(
      endDateMoment.diff(adjustedStartDateMoment, 'days')
    );

    if (differenceInDays > 15) {
      return totalMonthsBetween + 1;
    }
    if (differenceInDays === 0) {
      return totalMonthsBetween;
    }
    return totalMonthsBetween + 0.5;
  },

  isAfterDate: (dateFrom, dateTo) => {
    return moment(dateFrom, defaultDateFormat, true).isAfter(
      moment(dateTo, defaultDateFormat, true)
    );
  },

  isFutureDate: dateFrom =>
    moment(dateFrom).isAfter(getTodayDateString(), 'day'),

  isOnOrFutureDate: dateFrom =>
    moment(dateFrom).isSameOrAfter(getTodayDateString(), 'day'),

  isBeforeDate: (dateFrom, dateTo) => {
    return moment(dateFrom, defaultDateFormat, true).isBefore(
      moment(dateTo, defaultDateFormat, true)
    );
  },

  isOnOrPastDate: dateFrom =>
    moment(dateFrom).isSameOrBefore(getTodayDateString(), 'day'),

  isPastDate: dateFrom =>
    moment(dateFrom).isBefore(getTodayDateString(), 'day'),

  secondsSinceEpoch: currentTimeInMs => {
    return Math.floor(currentTimeInMs / 1000);
  },

  getFormattedDateString,

  getTodayMoment,
  getLastWeekMoment,
  getNextWeekMoment,
  getLastYearMoment,
  getNextYearMoment,
  getDaysBeforeNowMoment,
  getPastMoment: getDaysBeforeNowMoment,
  getYesterdayMoment,
  getDayBeforeYesterdayMoment,
  getDaysAfterNowMoment,
  getFutureMoment: getDaysAfterNowMoment,
  getTomorrowMoment,
  getDayAfterTomorrowMoment,

  getMomentFromString: getFormattedDateStringFromString,
  getFormattedDateStringFromString: s =>
    getFormattedDateString(getFormattedDateStringFromString(s)),

  getTodayDateString,
  getLastWeekDateString: () => getFormattedDateString(getLastWeekMoment()),
  getNextWeekDateString: () => getFormattedDateString(getNextWeekMoment()),
  getLastYearDateString: () => getFormattedDateString(getLastYearMoment()),
  getNextYearDateString: () => getFormattedDateString(getNextYearMoment()),
  getDaysBeforeNowDateString,
  getPastDateString: getDaysBeforeNowDateString,
  getYesterdayDateString: () => getDaysBeforeNowDateString(1),
  getDayBeforeYesterdayDateString: () => getDaysBeforeNowDateString(2),
  getDaysAfterNowDateString,
  getFutureDateString: getDaysAfterNowDateString,
  getTomorrowDateString: () => getDaysAfterNowDateString(1),
  getDayAfterTomorrowDateString: () => getDaysAfterNowDateString(2),

  getDateObject,
  getToday: () => getDateObject(getTodayMoment()),
  getYesterday: () => getDateObject(getYesterdayMoment()),
  getDayBeforeYesterday: () => getDateObject(getDayBeforeYesterdayMoment()),
  getTomorrow: () => getDateObject(getTomorrowMoment()),
  getDayAfterTomorrow: () => getDateObject(getDayAfterTomorrowMoment()),
};
