import { useEffect, useState, useMemo } from 'react';
import throttle from 'lodash/throttle';

/**
 * !!! Old util that will be converted during hubspot app scrub
 * Do not pull this into anywhere else !!!
 */
const breakpoints = {
  xs: 0,
  sm: 768,
  md: 992,
  lg: 1440,
  xl: 1920,
};

const calculateCurrentBreakpoint = windowWidth => {
  if (!windowWidth) {
    return undefined;
  } else if (windowWidth < breakpoints.sm) {
    return breakpoints.xs;
  } else if (windowWidth < breakpoints.md) {
    return breakpoints.sm;
  } else if (windowWidth < breakpoints.lg) {
    return breakpoints.md;
  } else if (windowWidth < breakpoints.xl) {
    return breakpoints.lg;
  }

  return breakpoints.xl;
};

/**
 * >.< This is the last holdout for useBreakpoints in this file
 * !!! Do not bring anywhere else because this will be going away !!!
 */
const useBreakpoints = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(
    calculateCurrentBreakpoint(window?.innerWidth)
  );

  useEffect(() => {
    const windowListener = throttle(event => {
      setCurrentBreakpoint(calculateCurrentBreakpoint(event.target.innerWidth));
    }, 100);

    window.addEventListener('resize', windowListener);

    return () => window.removeEventListener('resize', windowListener);
  }, []);

  return useMemo(
    () => ({ breakpoints, currentBreakpoint }),
    [currentBreakpoint]
  );
};

export const useFlexiColumnStructure = () => {
  const [numberOfColumns, setnumberOfColumns] = useState(2);
  const [isDesktop, setIsDesktop] = useState(true);
  const [isTablet, setIsTablet] = useState(false);
  const [isPhone, setIsPhone] = useState(false);

  const { currentBreakpoint } = useBreakpoints();

  useEffect(() => {
    const newIsDesktop = currentBreakpoint === breakpoints.xl;
    const newIsTablet =
      currentBreakpoint === breakpoints.sm ||
      currentBreakpoint === breakpoints.md ||
      currentBreakpoint === breakpoints.lg;
    const newIsPhone = currentBreakpoint === breakpoints.xs;

    let newNumberOfColumns;
    if (newIsPhone) {
      newNumberOfColumns = 1;
    } else if (newIsTablet) {
      newNumberOfColumns = 2;
    } else if (newIsDesktop) {
      newNumberOfColumns = 2;
    }

    setIsDesktop(newIsDesktop);
    setIsTablet(newIsTablet);
    setIsPhone(newIsPhone);
    setnumberOfColumns(newNumberOfColumns);
  }, [breakpoints, currentBreakpoint]);

  return { numberOfColumns, isDesktop, isTablet, isPhone };
};
