import jwt from 'jsonwebtoken';
import throttle from 'lodash/throttle';
import fetch from 'isomorphic-unfetch';

// How often to check if the token needs to be refreshed.
const DEFAULT_REFRESH_INTERVAL = 5 * 60 * 1000; // every 5 minutes

// Percentage of the token lifetime before which the token shouldn't be refreshed.
// (e.g. a 30m token shouldn't be refreshed unless it expires within 9 minutes if 70%)
const DEFAULT_REFRESH_THRESHOLD_PERCENTAGE = 0.7;

// The amount of time after a reset after which a token should be allowed to expire.
// (if set to 2 hours, a 30m token should be reset for the last time 1.5h after last reset)
// It will never take longer than this timeout to expire, but may expire early by
// up to DEFAULT_REFRESH_INTERVAL.
const DEFAULT_TIMEOUT = 2 * 60 * 60 * 1000; // 2 hours

// API Endpoint to use to exchange a still valid access token for a new one.
const DEFAULT_AUTH_ENDPOINT = '/api/auth';

export default class TokenRefresher {
  constructor(options) {
    this.options = options;
    this.token = options.initialToken;
    this.interval = null;
  }

  getToken() {
    return this.token;
  }

  resetTimeout() {
    if (!this.throttledReset) {
      this.throttledReset = throttle(() => this._resetTimeout(), 5000);
    }

    return this.throttledReset();
  }

  _resetTimeout() {
    const { refreshInterval = DEFAULT_REFRESH_INTERVAL } = this.options;

    this.stop();

    this.lastResetDate = new Date();
    this._refreshToken();
    this.interval = setInterval(() => this._refreshToken(), refreshInterval);
  }

  stop() {
    clearInterval(this.interval);
  }

  async _refreshToken() {
    const { authEndpoint = DEFAULT_AUTH_ENDPOINT } = this.options;

    if (!this._shouldRefreshToken()) {
      return;
    }

    let response;
    try {
      response = await fetch(authEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: this.getToken() }),
      });
    } catch (error) {
      // Just swallow the error, it will try again on the next refreshInterval.
      return;
    }

    // Just swallow the error, it will try again on the next refreshInterval.
    if (response.status !== 200) {
      return;
    }

    const { data: newToken } = await response.json();

    this.token = newToken;

    const { newTokenCallback } = this.options;
    if (newTokenCallback) {
      newTokenCallback(newToken);
    }
  }

  // Has side effects. If the token should never be refreshed, stops.
  _shouldRefreshToken() {
    const {
      refreshThresholdPercentage = DEFAULT_REFRESH_THRESHOLD_PERCENTAGE,
      timeout = DEFAULT_TIMEOUT,
    } = this.options;

    const token = this.getToken();

    if (!token) {
      this.stop();
      return false;
    }

    const decodedToken = jwt.decode(token, { json: true });

    const currentDate = new Date();
    const expirationDate = new Date(decodedToken.exp * 1000);
    const issuedDate = new Date(decodedToken.iat * 1000);

    const tokenLifetimeLength = expirationDate - issuedDate;
    const finalResetDate = new Date(
      this.lastResetDate.getTime() + timeout - tokenLifetimeLength
    );

    if (
      // If the token is already expired, can't refresh.
      expirationDate < currentDate ||
      // If timeout hasn't been restarted recently enough, stop refreshing token.
      currentDate > finalResetDate
    ) {
      this.stop();
      return false;
    }

    // If the token has been recently refreshed (perhaps in another tab), skip this refresh.
    const refreshThreshold = tokenLifetimeLength * refreshThresholdPercentage;
    if (currentDate - issuedDate < refreshThreshold) {
      return false;
    }

    return true;
  }
}
