const codes = {
  AUD: 'AUD',
  CAD: 'CAD',
  CHF: 'CHF',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  SGD: 'SGD',
  USD: 'USD',
} as const;

export const CurrencyMap = {
  codes,
  currencySymbols: {
    [codes.AUD]: 'A$',
    [codes.CAD]: 'CAN$',
    [codes.CHF]: 'CHF',
    [codes.EUR]: '€',
    [codes.GBP]: '£',
    [codes.HKD]: 'HK$',
    [codes.SGD]: 'S$',
    [codes.USD]: '$',
  },
  currenciesWithLabel: {
    [codes.USD]: `${codes.USD} - U.S. Dollar`,
    [codes.CAD]: `${codes.CAD} - Canadian Dollar`,
    [codes.GBP]: `${codes.GBP} - Great Britain Pound`,
    [codes.CHF]: `${codes.CHF} - Switzerland Franc`,
    [codes.EUR]: `${codes.EUR} - Euro`,
    [codes.HKD]: `${codes.HKD} - Hong Kong Dollar`,
    [codes.SGD]: `${codes.SGD} - Singapore Dollar`,
    [codes.AUD]: `${codes.AUD} - Australian Dollar`,
  },
  currenciesDelimitersByCurrencyCode: {
    [codes.AUD]: ',',
    [codes.CAD]: ',',
    [codes.GBP]: ',',
    [codes.CHF]: ',',
    [codes.EUR]: ',',
    [codes.HKD]: ',',
    [codes.SGD]: ',',
    [codes.USD]: ',',
  },
} as const;

export type CurrencyCodeKeyTypes = keyof typeof CurrencyMap.codes;
export type CurrencyCodeValueTypes =
  typeof CurrencyMap.codes[CurrencyCodeKeyTypes];
export type CurrencySymbolTypes =
  typeof CurrencyMap.currencySymbols[CurrencyCodeValueTypes];
export type CurrencyLabels =
  typeof CurrencyMap.currenciesWithLabel[CurrencyCodeValueTypes];
export type CurrencyDelimiters =
  typeof CurrencyMap.currenciesDelimitersByCurrencyCode[CurrencyCodeValueTypes];
