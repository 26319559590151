// eslint-disable-next-line max-classes-per-file
import { Enum } from 'enumify';
import { z } from 'zod';

import { ClientRequestConst, AfcRejectionLossCodes } from './clientRequests';

const CandidateStatuses = {
  Completed: 'Completed',
  Cooled: 'Cooled',
  Engaged: 'Engaged',
  Interested: 'Interested',
  InterestedRejected: 'InterestedRejected',
  Interviewing: 'Interviewing',
  Rejected: 'Rejected',
  Removed: 'Removed',
  RepBioShare: 'RepBioShare',
  Selected: 'Selected',
  ShortList: 'ShortList',
  Submitted: 'Submitted',
  Suggested: 'Suggested',
  TalentOptOut: 'TalentOptOut',
  Warmed: 'Warmed',
  WarmedUnsure: 'WarmedUnsure',
  WarmedYes: 'WarmedYes',
};

const CandidateStatusValues = Object.values(
  CandidateStatuses
) as NonEmptyArray<string>;

const CandidateStatusLabels = {
  Completed: 'Completed',
  Cooled: 'Cooled',
  Engaged: 'Engaged',
  Interested: 'Interested',
  InterestedRejected: 'Interested - Rejected',
  Interviewing: 'Interviewing',
  Rejected: 'Not Selected',
  Removed: 'Removed',
  RepBioShare: 'Rep Bio Shared',
  Selected: 'Selected',
  ShortList: 'Short Listed',
  Submitted: 'Submitted',
  Suggested: 'Suggested',
  TalentOptOut: 'Talent Opted Out',
  Warmed: 'Warmed',
  WarmedUnsure: 'Warmed - Unsure',
  WarmedYes: 'Talent Opted In',
};
const CandidateRejectionLossCodes = {
  ExperienceOverqualified: 'ExperienceOverqualified',
  ExperienceUnderqualified: 'ExperienceUnderqualified',
  ExperienceLackingIndustrySpecificExperience:
    'ExperienceLackingIndustrySpecificExperience',
  ExperiencePoorPracticeArea: 'ExperiencePoorPracticeArea',
  CostTooExpensive: 'CostTooExpensive',
  ConflictOfInterest: 'ConflictOfInterest',
  WrongTalentType: 'WrongTalentType',
  TimeZoneLocation: 'TimeZoneLocation',
  InsufficientAvailability: 'InsufficientAvailability',
  CannotWorkOnAdhocEngagements: 'CannotWorkOnAdhocEngagements',
  IncorrectLanguage: 'IncorrectLanguage',
  QualifiedCandidateThatWasNotSelected: 'QualifiedCandidateThatWasNotSelected',
  ExpressedInterestTooLateIntoProcess: 'ExpressedInterestTooLateIntoProcess',
  ProfileNeedsMoreDetailForSubmission: 'ProfileNeedsMoreDetailForSubmission',
};

const CandidateRejectionLossCodesValues = Object.values(
  CandidateRejectionLossCodes
) as NonEmptyArray<string>;

const CandidateRejectionLossCodesLabels = {
  ExperienceOverqualified: 'Experience - Overqualified',
  ExperienceUnderqualified: 'Experience - Underqualified',
  ExperienceLackingIndustrySpecificExperience:
    'Experience - Lacking Industry Specific Experience',
  ExperiencePoorPracticeArea: 'Experience - Poor Practice Area',
  CostTooExpensive: 'Cost - Too Expensive',
  ConflictOfInterest: 'Conflict of Interest',
  WrongTalentType: 'Wrong Talent Type',
  TimeZoneLocation: 'Time Zone/Location',
  InsufficientAvailability: 'Insufficient Availability',
  CannotWorkOnAdhocEngagements: 'Cannot work on ad-hoc engagements',
  IncorrectLanguage: 'Incorrect Language',
  QualifiedCandidateThatWasNotSelected:
    'Qualified Candidate That Was Not Selected',
  ExpressedInterestTooLateIntoProcess:
    'Expressed Interest Too Late into Process',
  ProfileNeedsMoreDetailForSubmission:
    'Profile Needs More Detail for Submission',
};

const CandidateOpportunityLossReasons = {
  ...CandidateRejectionLossCodes,
  UnremediatedWon: 'UnremediatedWon',
  UnremediatedLost: 'UnremediatedLost',
};

const CandidateOpportunityLossReasonMessages = {
  [CandidateOpportunityLossReasons.ExperienceOverqualified]: `Your profile was not submitted for this opportunity as it didn't match the required level of experience as determined by the client. Consider expressing interest in opportunities that require a little more experience or highly specialized work.`,
  [CandidateOpportunityLossReasons.ExperienceUnderqualified]: `Your profile was not submitted for this opportunity as the client was looking for more experience. Consider taking a moment to ensure your Axiom profile includes all your legal experience and skills.`,
  [CandidateOpportunityLossReasons.ExperienceLackingIndustrySpecificExperience]: `Your profile was not submitted for this opportunity as your background didn't have the relevant industry experience. Consider filtering opportunities by industry to express interest in roles that match your industry experience. It's also important to associate a relevant industry with every experience in your Axiom profile.`,
  [CandidateOpportunityLossReasons.ExperiencePoorPracticeArea]: `Your profile was not submitted for this opportunity because your background didn't have the relevant practice area. Consider filtering opportunities by practice area to express interest in roles that match up well with your legal background.`,
  [CandidateOpportunityLossReasons.CostTooExpensive]: `Your profile was not submitted for this opportunity due to client budget constraints. Consider expressing interest in opportunities that require a little more experience or highly specialized work.`,
  [CandidateOpportunityLossReasons.ConflictOfInterest]: `Your profile was not submitted for this opportunity due to a conflict of interest that might prevent you from performing the work. If you're not sure you have a conflict of interest with a client, it's a good idea to check with your Talent Success Partner before expressing interest.`,
  [CandidateOpportunityLossReasons.WrongTalentType]: `Your profile was not submitted for this opportunity because your legal role is different than what the client requires. Consider filtering opportunities by legal role to express interest in roles that match your qualifications.`,
  [CandidateOpportunityLossReasons.TimeZoneLocation]: `Your profile was not submitted for this opportunity because your location or time zone doesn't match up with what the client is looking for. Consider filtering opportunities by country or double-checking the opportunity location to ensure your work schedule aligns with the client's local business hours.`,
  [CandidateOpportunityLossReasons.InsufficientAvailability]: `Your profile was not submitted for this opportunity because your availability doesn't match up with the client's requirements. Consider filtering opportunities by weekly commitment to express interest in roles that match your availability. It's also important to ensure your availability is up to date in your Axiom profile.`,
  [CandidateOpportunityLossReasons.CannotWorkOnAdhocEngagements]: `Your profile was not submitted for this opportunity because the hours required for this engagement do not meet the minimums required for your state. Consider expressing interest in engagements with more of a time commitment required.`,
  [CandidateOpportunityLossReasons.IncorrectLanguage]: `Your profile was not submitted for this opportunity because you don't have the required language skill set. Consider taking a moment to ensure all languages you speak, read, or write are included in your Axiom profile.`,
  [CandidateOpportunityLossReasons.QualifiedCandidateThatWasNotSelected]: `You weren't selected for this opportunity since the client has selected another legal professional for this opportunity.`,
  [CandidateOpportunityLossReasons.ExpressedInterestTooLateIntoProcess]: `You weren't submitted for this opportunity because the client is not reviewing any more profiles at this time. While some opportunities do get filled quickly, Axiom has you covered--the feed is refreshed daily with more opportunities like this.`,
  [CandidateOpportunityLossReasons.ProfileNeedsMoreDetailForSubmission]: `You weren't selected for this opportunity because your Axiom for Talent profile is incomplete. Consider taking a moment to check your Axiom profile completion % and add any missing information.`,
  [CandidateOpportunityLossReasons.UnremediatedWon]: `It's not you, it's us. This opportunity was very popular, and the role was filled before we could consider all interested talent. We know this isn't ideal and we're working on it. Thanks for your patience and we encourage you to keep checking the feed for more opportunities like this.`,
  [CandidateOpportunityLossReasons.UnremediatedLost]: `The opportunity is no longer available due to a change in client needs. This can happen for many reasons but some of the top ones include an internal hire, reduction of budget eliminating the need for talent, or client simply stopped replying.`,
};

// TODO this is the beginning of changing to a new format for constants
class CandidateStatusEnum extends Enum {}
CandidateStatusEnum.initEnum({
  [CandidateStatuses.Completed]: { v: CandidateStatusLabels.Completed },
  [CandidateStatuses.Cooled]: { v: CandidateStatusLabels.Cooled },
  [CandidateStatuses.Engaged]: { v: CandidateStatusLabels.Engaged },
  [CandidateStatuses.Interested]: { v: CandidateStatusLabels.Interested },
  [CandidateStatuses.InterestedRejected]: {
    v: CandidateStatusLabels.InterestedRejected,
  },
  [CandidateStatuses.Interviewing]: { v: CandidateStatusLabels.Interviewing },
  [CandidateStatuses.Rejected]: { v: CandidateStatusLabels.Rejected },
  [CandidateStatuses.Removed]: { v: CandidateStatusLabels.Removed },
  [CandidateStatuses.RepBioShare]: { v: CandidateStatusLabels.RepBioShare },
  [CandidateStatuses.Selected]: { v: CandidateStatusLabels.Selected },
  [CandidateStatuses.ShortList]: { v: CandidateStatusLabels.ShortList },
  [CandidateStatuses.Submitted]: { v: CandidateStatusLabels.Submitted },
  [CandidateStatuses.Suggested]: { v: CandidateStatusLabels.Suggested },
  [CandidateStatuses.TalentOptOut]: { v: CandidateStatusLabels.TalentOptOut },
  [CandidateStatuses.Warmed]: { v: CandidateStatusLabels.Warmed },
  [CandidateStatuses.WarmedUnsure]: { v: CandidateStatusLabels.WarmedUnsure },
  [CandidateStatuses.WarmedYes]: { v: CandidateStatusLabels.WarmedYes },
});
CandidateStatusEnum.v = v =>
  CandidateStatusEnum.enumValues.find(x => x.v === v);

// Note: These should not conflict with CandidateStatuses otherwise we will have issues
const OppCandMlRecStatuses = {
  AiAccepted: 'AI Accepted', // decoration on opp_cand_ml_recs only
  AiRemoved: 'AI Removed', // decoration on opp_cand_ml_recs only
  AiSuggested: 'AI Suggested', // valid previousCandidateStatus in candidate_opportunities
};

const OppCandMlRecStatusLabels = {
  [OppCandMlRecStatuses.AiSuggested]: 'AI Match',
  [OppCandMlRecStatuses.AiRemoved]: 'AI Match - Removed',
};

const ClientRequestSudoStatus = {
  [ClientRequestConst.RequestTypes.InterviewRequested]:
    'AFC Interview Requested',
  [ClientRequestConst.RequestTypes.ClientPassed]: 'AFC Pass',
  [ClientRequestConst.RequestTypes.HireRequested]: 'AFC Selected',
};

const AfcRequestTypeToCandidateStatus = {
  [ClientRequestConst.RequestTypes.ClientPassed]: CandidateStatuses.Rejected,
  [ClientRequestConst.RequestTypes.HireRequested]: CandidateStatuses.Selected,
  [ClientRequestConst.RequestTypes.InterviewRequested]:
    CandidateStatuses.Interviewing,
};

const AfcLossCodesToCandidateOpportunityLossCodes: { [x: string]: string } = {
  [AfcRejectionLossCodes.ExperienceOverqualified]:
    CandidateRejectionLossCodes.ExperienceOverqualified,
  [AfcRejectionLossCodes.ExperienceUnderqualified]:
    CandidateRejectionLossCodes.ExperienceUnderqualified,
  [AfcRejectionLossCodes.ExperienceLackingIndustrySpecificExperience]:
    CandidateRejectionLossCodes.ExperienceLackingIndustrySpecificExperience,
  [AfcRejectionLossCodes.CostTooExpensive]:
    CandidateRejectionLossCodes.CostTooExpensive,
  [AfcRejectionLossCodes.ConflictOfInterest]:
    CandidateRejectionLossCodes.ConflictOfInterest,
  [AfcRejectionLossCodes.TimeZoneLocation]:
    CandidateRejectionLossCodes.TimeZoneLocation,
  [AfcRejectionLossCodes.IncorrectLanguage]:
    CandidateRejectionLossCodes.IncorrectLanguage,
  [AfcRejectionLossCodes.QualifiedCandidateThatWasNotSelected]:
    CandidateRejectionLossCodes.QualifiedCandidateThatWasNotSelected,
  [AfcRejectionLossCodes.ProfileNeedsMoreDetail]:
    CandidateRejectionLossCodes.ProfileNeedsMoreDetailForSubmission,
  [AfcRejectionLossCodes.Other]: null,
};

class CandidateRejectionLossCodesEnum extends Enum {}
CandidateRejectionLossCodesEnum.initEnum({
  [CandidateRejectionLossCodes.ExperienceOverqualified]: {
    v: CandidateRejectionLossCodesLabels.ExperienceOverqualified,
  },
  [CandidateRejectionLossCodes.ExperienceUnderqualified]: {
    v: CandidateRejectionLossCodesLabels.ExperienceUnderqualified,
  },
  [CandidateRejectionLossCodes.ExperienceLackingIndustrySpecificExperience]: {
    v: CandidateRejectionLossCodesLabels.ExperienceLackingIndustrySpecificExperience,
  },
  [CandidateRejectionLossCodes.ExperiencePoorPracticeArea]: {
    v: CandidateRejectionLossCodesLabels.ExperiencePoorPracticeArea,
  },
  [CandidateRejectionLossCodes.CostTooExpensive]: {
    v: CandidateRejectionLossCodesLabels.CostTooExpensive,
  },
  [CandidateRejectionLossCodes.ConflictOfInterest]: {
    v: CandidateRejectionLossCodesLabels.ConflictOfInterest,
  },
  [CandidateRejectionLossCodes.WrongTalentType]: {
    v: CandidateRejectionLossCodesLabels.WrongTalentType,
  },
  [CandidateRejectionLossCodes.TimeZoneLocation]: {
    v: CandidateRejectionLossCodesLabels.TimeZoneLocation,
  },
  [CandidateRejectionLossCodes.InsufficientAvailability]: {
    v: CandidateRejectionLossCodesLabels.InsufficientAvailability,
  },
  [CandidateRejectionLossCodes.CannotWorkOnAdhocEngagements]: {
    v: CandidateRejectionLossCodesLabels.CannotWorkOnAdhocEngagements,
  },
  [CandidateRejectionLossCodes.IncorrectLanguage]: {
    v: CandidateRejectionLossCodesLabels.IncorrectLanguage,
  },
  [CandidateRejectionLossCodes.QualifiedCandidateThatWasNotSelected]: {
    v: CandidateRejectionLossCodesLabels.QualifiedCandidateThatWasNotSelected,
  },
  [CandidateRejectionLossCodes.ExpressedInterestTooLateIntoProcess]: {
    v: CandidateRejectionLossCodesLabels.ExpressedInterestTooLateIntoProcess,
  },
  [CandidateRejectionLossCodes.ProfileNeedsMoreDetailForSubmission]: {
    v: CandidateRejectionLossCodesLabels.ProfileNeedsMoreDetailForSubmission,
  },
});
CandidateRejectionLossCodesEnum.v = v =>
  CandidateRejectionLossCodesEnum.enumValues.find(x => x.v === v);

const RollOffReasonsCodes = {
  TalentLeftAxiom: 'TalentLeftAxiom',
  TalentLeftEngagement: 'TalentLeftEngagement',
  TalentPerformanceIssue: 'TalentPerformanceIssue',
  BudgetLost: 'BudgetLost',
  CoEmploymentCap: 'CoEmploymentCap',
  Conversion: 'Conversion',
  CleanUp: 'CleanUp',
  TransitionToAnother: 'TransitionToAnother',
  ProjectCompleted: 'ProjectCompleted',
  WrongFit: 'WrongFit',
  FalseStart: 'FalseStart',
};
const RollOffReasons = {
  [RollOffReasonsCodes.TalentLeftAxiom]: 'Talent Left Axiom',
  [RollOffReasonsCodes.TalentLeftEngagement]: 'Talent Left Engagement',
  [RollOffReasonsCodes.TalentPerformanceIssue]: 'Talent Performance Issue',
  [RollOffReasonsCodes.BudgetLost]: 'Budget Lost',
  [RollOffReasonsCodes.CoEmploymentCap]: 'Co-Employment Cap',
  [RollOffReasonsCodes.Conversion]: 'Conversion',
  [RollOffReasonsCodes.CleanUp]: 'Data/BoB Clean-up',
  [RollOffReasonsCodes.TransitionToAnother]:
    'Work transitioned to new Headcount',
  [RollOffReasonsCodes.ProjectCompleted]: 'Project/ Workstream Completed',
  [RollOffReasonsCodes.WrongFit]: 'Talent Wrong Skillset/Fit',
  [RollOffReasonsCodes.FalseStart]: 'False Start',
};

// eslint-disable-next-line import/prefer-default-export
export const CandidateOpportunitiesConst = {
  AfcRequestTypeToCandidateStatus,
  AfcLossCodesToCandidateOpportunityLossCodes,
  AllStatusLabels: {
    ...CandidateStatusLabels,
    ...OppCandMlRecStatusLabels,
    ...ClientRequestSudoStatus,
  },
  CandidateRejectionLossCode: CandidateRejectionLossCodesEnum,
  CandidateRejectionLossCodes,
  CandidateRejectionLossCodesLabels,
  ZodCandidateRejectionLossCodes: z.enum(CandidateRejectionLossCodesValues),
  CandidateOpportunityLossReasons,
  CandidateOpportunityLossReasonMessages,
  CandidateStatus: CandidateStatusEnum,
  // statuses persisted and used internally
  CandidateStatuses,
  ZodCandidateStatuses: z.enum(CandidateStatusValues),
  // status labels to show the user
  CandidateStatusLabels,
  CompensationTypes: {
    EngagementSpecific: 'Engagement Specific',
    Premium: 'Premium',
    Standard: 'Standard',
  },
  DisplayBillingRate: {
    hourly: 'hourly',
    daily: 'daily',
    weekly: 'weekly',
    none: 'none',
  },
  OppCandMlRecFilters: {
    notMlAccepted: 'notMlAccepted',
  },
  OppCandMlRecStatuses,
  OppCandMlRecStatusLabels,
  ReasonForNo: {
    DontHaveTime: 'Dont Have Time',
    WrongAreaOfExpertise: 'Wrong Area of Expertise',
    InconvenientLocation: 'Inconvenient Location',
    Other: 'Other',
  },
  OptInQuestions: {
    hasNoConflictOfInterest:
      'I certify that this opportunity does not present any actual or potential conflicts of interest that would prevent me from acting for the client on this matter.',
    candidateExploringOutsideRoles:
      'Are you currently exploring opportunities outside of Axiom?',
    candidatePlannedTimeOffNotes:
      'Do you have any extended time off planned over the next few months? If so, when exactly will you be unavailable?',
    candidateAvailabilityNotes:
      'How soon would you be available to start this engagement?',
    candidateExperienceNotes:
      "Is there anything specific in your experience that you'd like us to highlight with the client?",
  },
  RollOffReasons,
  RollOffReasonsCodes,
  UnremediatedStatuses: [
    CandidateStatuses.Interested,
    CandidateStatuses.ShortList,
    CandidateStatuses.Suggested,
  ],
  /** field names */
  ANNUAL_SALARY: 'annualSalary',
  CANDIDATE_AVAILABILITY_NOTES: 'candidateAvailabilityNotes',
  CANDIDATE_EXPERIENCE_NOTES: 'candidateExperienceNotes',
  CANDIDATE_ID: 'candidateId',
  CANDIDATE_REASON_FOR_NO: 'candidateReasonForNo',
  CANDIDATE_STATUS: 'candidateStatus',
  CANDIDATE_STATUS_MESSAGE: 'candidateStatusMessage',
  CREATED_AT: 'createdAt',
  DISPLAY_BILLING_RATE: 'displayBillingRate',
  HOURLY_SALARY: 'hourlySalary',
  ID: 'id',
  OPPORTUNITY_ID: 'opportunityId',
  PREVIOUS_CANDIDATE_STATUS: 'previousCandidateStatus',
  PRPOSED_HOURLY_RATE: 'proposedHourlyRate',
  SUBMISSION_DESCRIPTION: 'submissionDescription',
  UPDDATED_AT: 'updatedAt',
};

export const CandidateOpportunitiesConstWarmedStatuses = [
  CandidateStatuses.Warmed,
  CandidateStatuses.Selected,
  CandidateStatuses.WarmedYes,
  CandidateStatuses.Submitted,
  CandidateStatuses.Interviewing,
];

export type CandidateStatusType = keyof typeof CandidateStatuses;

export const InConsiderationCandidateStatuses = [
  CandidateStatuses.Selected,
  CandidateStatuses.Interested,
  CandidateStatuses.ShortList,
  CandidateStatuses.RepBioShare,
  CandidateStatuses.Warmed,
  CandidateStatuses.WarmedUnsure,
  CandidateStatuses.WarmedYes,
  CandidateStatuses.Submitted,
  CandidateStatuses.Interviewing,
  CandidateStatuses.Suggested,
];

export const CurrentEngagementCandidateStatuses = [CandidateStatuses.Engaged];

export const PreviousEngagementCandidateStatuses = [
  CandidateStatuses.Completed,
];

export const NoLongerInConsiderationCandidateStatuses = [
  CandidateStatuses.TalentOptOut,
  CandidateStatuses.Rejected,
  CandidateStatuses.Removed,
  CandidateStatuses.InterestedRejected,
  CandidateStatuses.Cooled,
];

export const CandidateStatusLabelsMap = new Map(
  Object.entries(CandidateStatusLabels)
);
