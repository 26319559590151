import { ResponseProps } from './api-types';

export class ApiError extends Error {
  private apiError: {
    body?: ResponseProps['body'];
    endpoint: ResponseProps['endpoint'];
    errors?: unknown;
    headers: ResponseProps['headers'];
    method: ResponseProps['method'];
    status: ResponseProps['status'];
    statusCode: ResponseProps['status'];
    response?: unknown;
  };

  constructor({
    endpoint,
    method,
    status,
    body,
    response,
    headers,
  }: ResponseProps) {
    super(`${status} - Api Error`);

    // eslint-disable-next-line no-nested-ternary
    const errors =
      (body as { errors?: Array<string> })?.errors ||
      (body as { error?: string })?.error ||
      body;

    this.apiError = {
      endpoint,
      method,
      status,
      statusCode: status,
      response,
      errors,
      headers,
    };
  }

  getStatusCode() {
    return this.apiError.statusCode;
  }

  getEndpoint() {
    return this.apiError.endpoint;
  }

  getErrors() {
    return this.apiError.errors;
  }

  getMethod() {
    return this.apiError.method;
  }

  getHeaders() {
    return this.apiError.headers;
  }

  getResponse() {
    return this.apiError.response;
  }
}
