import React from 'react';
import { Modal as SModal } from 'semantic-ui-react';

type SizeType = 'default' | 'confirm' | 'large';

type SemanticSizeType = 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';

const sizeMap = {
  large: 'large',
  default: 'small',
  confirm: 'tiny',
};

export interface ModalProps {
  className?: string;
  name?: string;
  size?: SizeType;
  children?: React.ReactNode;
}

export const Modal = ({
  name,
  className,
  size = 'default',
  children,
}: ModalProps) => {
  return (
    <SModal
      name={name}
      className={className}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      defaultOpen
      size={sizeMap[size] as SemanticSizeType}
      data-test={name || 'MODAL_WRAPPER_CONTAINER'}
      // Modal needs to mount to root. Mounting directly to document.body causes compatibility issues with Sapling
      mountNode={document.getElementById('root')}
    >
      {children}
    </SModal>
  );
};
