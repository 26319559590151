import styled, { css } from 'styled-components';
import { MediaQueryUtil } from '@axiom/ui';
import { AxiomTheme } from '@axiom/ui/src/theme/axiom-theme';

export const DivDefaultLayoutWrapper = styled.div`
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  overflow: auto;
  display: flex;
  flex-direction: column;

  ${MediaQueryUtil.smallScreenOnly(css`
    position: relative;
    display: block;

    .card {
      border: none;
    }
    .bg-secondary {
      background-color: white !important;
      border: none;
    }
    footer.bg-secondary {
      background-color: ${AxiomTheme.colors.controlSecondary};
    }
  `)}
`;

export const DivBodyWrapper = styled.div<{ disableBackgroundImage?: boolean }>`
  position: relative;
  flex: 1;
  background: ${AxiomTheme.colors.panelGray};

  .ie-warning {
    height: auto;
  }
`;
