/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import chunk from 'lodash/chunk';
import { isFragment } from 'react-is';
import styled, { css } from 'styled-components';
import { arrayOf, shape, func, number, oneOfType, string } from 'prop-types';

import { MediaQueryUtil } from '../../../utils/media-query-util';
import { DataTestUtil } from '../../../utils/data-test-util';
import { Grid } from '../../layout/Grid/Grid';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { GridRow } from '../../layout/Grid/GridRow';

import { useFlexiColumnStructure } from './FlexiCardGridUtils';

const flattenChildren = children => {
  const result = [];
  React.Children.forEach(children, child => {
    if (isFragment(child)) {
      result.push(...flattenChildren(child.props.children));
    } else {
      result.push(child);
    }
  });
  return result;
};

/**
 * For multiItems:
 * [
 *    [
 *      [ child1row1, child2row1, child3row1 ]
 *      [ child1row2, child2row2, child3row2 ]
 *    ]
 *    [
 *      [ child4row1, child5row1, child6row1 ]
 *      [ child4row2, child5row2, child6row2 ]
 *    ]
 * ]
 *
 * For singleItem:
 * [
 *  [child1row1, child1row2]
 *  [child2row1, child2row2]
 * ]
 *
 *
 * For multi cards in a row, we have to essentially pull out each card header/body in the card and reorder
 * it in a grid format so that the grid is essentially a row of cards, each row is the horizontal section of each card bit
 * and the column is the "cell" or child bit of a card, so a body or header component
 *
 * One one card per row, it just stacks and builds like you would expect
 */

const constructTemplateRows = (items, renderCard, numberOfColumns) => {
  let totalChildRows = null;

  return chunk(items, numberOfColumns).reduce(
    (acc, chunkedItems, chunkedItemsIndex) => {
      chunkedItems.forEach((item, index) => {
        let renderedCard;
        if (item.renderedAppend) {
          renderedCard = item.renderedAppend;
        } else {
          renderedCard = renderCard({ dataItem: item });
        }
        const renderedCardRows = flattenChildren(renderedCard, item);
        if (!acc.multiItems.length) {
          totalChildRows = renderedCardRows.length;
        } else if (totalChildRows !== renderedCardRows.length) {
          /**
           * Every card needs to have the same amount of children otherwise it skews the display of the grid
           */
          throw new Error(
            `Received a card that has ${renderedCardRows.length} rows instead of ${totalChildRows}`
          );
        }

        if (!Array.isArray(acc.multiItems[chunkedItemsIndex])) {
          acc.multiItems[chunkedItemsIndex] = [];
        }

        renderedCardRows.forEach((child, cIndex) => {
          if (!Array.isArray(acc.multiItems[chunkedItemsIndex][cIndex])) {
            acc.multiItems[chunkedItemsIndex][cIndex] = [];
          }
          acc.multiItems[chunkedItemsIndex][cIndex][index] = child;
        });

        acc.singleItem.push(renderedCard);
      });

      return acc;
    },
    { multiItems: [], singleItem: [] }
  );
};

const FlexiCardGridWrapper = styled.div`
  // Ensures long obnoxious words are split hyphened for anything in the card.
  // Something like W7Xdu9Bq459ABG2QLYDwc8SowIvuj7E3y0WGRW7Xdu9Bq459ABG2QLYDwc8SowIvuj7E3y0WGRW7Xdu9Bq459ABG2QLYDwc8SowIvuj7E3y0WGRW7Xdu9Bq459ABG2QLYDwc8SowI
  // was it was breaking out of the card into infinity

  flex: 1;
  width: 100%;

  .card-grid-column {
    overflow-wrap: anywhere;
    hyphens: auto;
  }
  .card-grid-multiItems {
    margin-bottom: 2rem !important;
  }

  .card-grid-multiItems .card-grid-row .card-grid-column > * {
    padding-left: 26px;
    padding-right: 26px;
  }

  .card-grid-multiItems .card-grid-row:first-child .card-grid-column > * {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 20px;
    padding-bottom: 16px;
  }

  .card-grid-multiItems .card-grid-row:last-child .card-grid-column > * {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 25px;
  }

  .card-grid-multiItems .card-grid-row:nth-child(2) .card-grid-column > * {
    padding-top: 20px;
  }

  .card-grid-singleItem .card-grid-row .card-grid-column > * {
    padding-left: 26px;
    padding-right: 26px;
  }

  .card-grid-singleItem .card-grid-row:first-child .card-grid-column > * {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .card-grid-singleItem .card-grid-row .card-grid-column > *:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-bottom: 16px;
  }

  .card-grid-singleItem .card-grid-row .card-grid-column > *:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 25px;
  }

  .card-grid-singleItem .card-grid-row .card-grid-column > *:not(:last-child) {
    padding-top: 20px;
  }

  ${MediaQueryUtil.smallScreenOnly(css`
    .card-grid-singleItem .card-grid-column {
      margin-bottom: 1rem !important;
    }
  `)}
`;

const FlexiCardRow = ({ renderCard, items }) => {
  const { numberOfColumns, isPhone } = useFlexiColumnStructure();

  const templates = useMemo(
    () => constructTemplateRows(items, renderCard, numberOfColumns),
    [items, renderCard, numberOfColumns]
  );

  if (!templates.multiItems.length) {
    return null;
  }

  return isPhone ? (
    <Grid columns={1} className="card-grid-singleItem">
      <GridRow className="card-grid-row" stretched stacked>
        {templates.singleItem.map((rowChildren, rowChildIndex) => (
          <GridColumn
            stretched
            className="card-grid-column"
            key={`flexiRowChild${rowChildIndex}`}
          >
            {rowChildren}
          </GridColumn>
        ))}
      </GridRow>
    </Grid>
  ) : (
    templates.multiItems.map((mappedRow, index) => {
      return (
        <Grid
          columns={numberOfColumns}
          className="card-grid-multiItems"
          key={`flexGrid${index}`}
        >
          {mappedRow.map((rowChildren, rowIndex) => (
            <GridRow
              className="card-grid-row"
              stretched
              key={`flexGridRow${rowIndex}`}
            >
              {React.Children.map(rowChildren, child => (
                <GridColumn className="card-grid-column">{child}</GridColumn>
              ))}
            </GridRow>
          ))}
        </Grid>
      );
    })
  );
};

FlexiCardRow.propTypes = {
  items: arrayOf(shape({})).isRequired,
  renderCard: func.isRequired,
};

export const FlexiCardGrid = ({ data, renderCard, name }) => (
  <FlexiCardGridWrapper data-test={DataTestUtil.format(name)}>
    <FlexiCardRow items={data} renderCard={renderCard} />
  </FlexiCardGridWrapper>
);
FlexiCardGrid.defaultProps = {
  name: 'Flexi_Card_Grid',
};
FlexiCardGrid.propTypes = {
  data: arrayOf(shape({ id: oneOfType([string, number]) })).isRequired,
  renderCard: func.isRequired,
  name: string,
};
