/** Certification */

// eslint-disable-next-line import/prefer-default-export
export const Certification = {
  /** field names */
  AWARD: 'award',
  CANDIDATE_ID: 'candidateId',
  CREATED_AT: 'createdAt',
  INSTITUTION: 'institution',
  YEAR: 'year',
  ID: 'id',
  UPDATED_AT: 'updatedAt',
  QUALIFICATION_AWARDS: {
    QUALIFICATION: 'Qualification',
    YEAR_OF_CALL: 'Year of Call',
    STATE_EXAM: 'State Exam',
  },
  FORM_TYPES: {
    QUALIFICATIONS: 'Qualifications',
    ACCOMPLISHMENTS: 'Accomplishments', // new in AFT
    CERTIFICATIONS_AND_AWARDS: 'Certifications & Awards', // old and still active in AHQ
  },
};
