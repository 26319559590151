import { CookieTokenRefresher } from '@axiom/auth';
import isPlainObject from 'lodash/isPlainObject';
import { apiRequest } from '@axiom/ui/src/classes/api-request';
import { Subject } from 'rxjs';
import { BlockerUtil } from '@axiom/ui';

import { DesignSysEnvUtil } from '../utils/design-sys-env-util';

import { RequestProps } from './api-types';
import { EndpointCache, FetchWatcher } from './endpoint-cache';

const isBrowserProcess = (process: unknown): process is { browser: boolean } =>
  !!(process as { browser: boolean })?.browser;

type readParams = {
  body?: RequestProps['body'];
  disableCookieRefresh?: boolean;
  encodeQuery?: RequestProps['encodeQuery'];
  endpoint: RequestProps['endpoint'];
  headers?: RequestProps['headers'];
  method: RequestProps['method'];
  query?: RequestProps['query'];
};

type writeParams = {
  body?: RequestProps['body'];
  disableCookieRefresh?: boolean;
  encodeQuery?: RequestProps['encodeQuery'];
  endpoint: RequestProps['endpoint'];
  headers?: RequestProps['headers'];
  method: RequestProps['method'];
  query?: RequestProps['query'];
};

/**
 * wipeStoreFetchers to only be used by tests
 */
export const wipeStoreFetchers = new Subject();
/**
 * end test note
 */

export class Api {
  private domain: string;

  private cookieDomain: string;

  private cookieTokenRefresher: CookieTokenRefresher;

  private store: Record<string, EndpointCache<unknown>>;

  constructor({
    domain,
    cookieDomain,
  }: {
    domain: string;
    cookieDomain?: string;
  }) {
    if (!domain) {
      throw new Error('You must provide a domain for the api to call');
    }
    this.domain = domain;
    this.cookieDomain = cookieDomain;
    this.store = {};

    if (isBrowserProcess(process)) {
      this.cookieTokenRefresher = new CookieTokenRefresher({
        cookieDomain: () => `${this.getCookieDomain()}`,
      });
    }

    wipeStoreFetchers.subscribe({
      next: () => {
        Object.keys(this.store).forEach(
          storeKey => delete this.store[storeKey]
        );
      },
    });
  }

  getCookieDomain() {
    return this.cookieDomain || DesignSysEnvUtil.cookieDomain;
  }

  read<ResponseType = void>({
    body,
    disableCookieRefresh,
    encodeQuery,
    endpoint,
    headers,
    method,
    query,
  }: readParams &
    (ResponseType extends void
      ? 'Must provide ResponseType'
      : readParams)): FetchWatcher<ResponseType> {
    return this.getStore<ResponseType>(endpoint).watch({
      body,
      cookieDomain: this.getCookieDomain(),
      cookieTokenRefresher: disableCookieRefresh && this.cookieTokenRefresher,
      domain: this.domain,
      encodeQuery,
      endpoint,
      headers,
      method,
      query,
    });
  }

  write<ResponseType = void>({
    body,
    disableCookieRefresh,
    encodeQuery,
    endpoint,
    headers,
    method,
    query,
  }: writeParams &
    (ResponseType extends void
      ? 'Must provide ResponseType'
      : writeParams)): Promise<ResponseType | Record<string, never>> {
    if (
      method === 'PATCH' &&
      body &&
      isPlainObject(body) &&
      Object.keys(body).length === 0
    ) {
      return Promise.resolve({});
    }
    const blockerEvent = BlockerUtil.block();
    return apiRequest({
      body,
      cookieDomain: this.getCookieDomain(),
      cookieTokenRefresher: disableCookieRefresh && this.cookieTokenRefresher,
      domain: this.domain,
      encodeQuery,
      endpoint,
      headers,
      method,
      query,
    })
      .then(data => {
        BlockerUtil.unblock(blockerEvent);
        return data as Promise<ResponseType | Record<string, never>>;
      })
      .catch(e => {
        BlockerUtil.unblock(blockerEvent);
        throw e;
      });
  }

  invalidate(endpoint: string) {
    return this.store[endpoint]?.invalidate();
  }

  invalidateAll() {
    Object.values(this.store).map(store => store.invalidate());
  }

  clear(endpoint: string) {
    this.store[endpoint]?.clear();
  }

  private getStore<ResponseType>(url: string): EndpointCache<ResponseType> {
    if (!this.store[url]) {
      this.store[url] = new EndpointCache();
    }

    return this.store[url] as EndpointCache<ResponseType>;
  }
}
