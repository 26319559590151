import React from 'react';
import { colors, shadows } from '@axiom/ui/src/theme/axiom-theme';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export const LayoutItem = ({
  background,
  border,
  borderLeft,
  borderRight,
  borderTop,
  borderBottom,
  borderRadius,
  bottomGutter,
  boxShadow,
  children,
  className,
  fluid,
  horizontalGutter,
  hug,
  leftGutter,
  name,
  position = 'left',
  rightGutter,
  topGutter,
  verticalGutter,
  stretched,
}: {
  background?: keyof typeof colors | CssBackgroundImage;
  border?: string; // TODO APCORE-1551 Make this work: `${string}px ${string} ${keyof typeof colors}`
  borderLeft?: string;
  borderRight?: string;
  borderTop?: string;
  borderBottom?: string;
  borderRadius?: string;
  bottomGutter?: string;
  boxShadow?: keyof typeof shadows;
  children?: React.ReactNode;
  className?: string;
  fluid?: boolean;
  horizontalGutter?: string;
  hug?: boolean;
  leftGutter?: string;
  name?: string;
  position?: 'left' | 'right' | 'center';
  rightGutter?: string;
  topGutter?: string;
  verticalGutter?: string;
  stretched?: boolean;
}) => {
  const borderL = borderLeft || border;
  const borderR = borderRight || border;
  const borderT = borderTop || border;
  const borderB = borderBottom || border;
  const styles = {
    backgroundColor: typeof background === 'string' ? colors[background] : null,
    ...(background && typeof background === 'object'
      ? {
          backgroundImage: `url("${background.image}")`,
          backgroundAttachment: background.attachment,
          backgroundClip: background.clip,
          backgroundColor: background.color,
          backgroundPosition: background.position || 'center right',
          backgroundRepeat: background.repeat || 'no-repeat',
        }
      : {}),
    borderLeft: borderL ? AttrsHelper.replaceColorNameWithHex(borderL) : null,
    borderRight: borderR ? AttrsHelper.replaceColorNameWithHex(borderR) : null,
    borderTop: borderT ? AttrsHelper.replaceColorNameWithHex(borderT) : null,
    borderBottom: borderB ? AttrsHelper.replaceColorNameWithHex(borderB) : null,
    boxShadow: boxShadow && shadows[boxShadow],
    borderRadius,
    paddingBottom: bottomGutter || verticalGutter,
    paddingLeft: leftGutter || horizontalGutter,
    paddingRight: rightGutter || horizontalGutter,
    paddingTop: topGutter || verticalGutter,
    textAlign: position,
  };
  return (
    <div
      className={AttrsHelper.formatClassname(
        'layout-item',
        fluid && 'fluid-item',
        hug && 'hug-item',
        stretched && 'layout-stretched',
        className
      )}
      data-test={name}
      style={styles}
    >
      {children}
    </div>
  );
};
