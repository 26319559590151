import React from 'react';
import { MovingMailIcon } from '@axiom/ui';

import CommonCardConfirmation from '../CommonCardConfirmation/CommonCardConfirmation';

const TokenLogin = () => (
  <CommonCardConfirmation title="Check your email" icon={<MovingMailIcon />}>
    If we're able to find your account we will email you a sign-in link. If you
    do not receive the email, check your spam folder before reaching out to{' '}
    <a href="mailto:client.answers@axiomlaw.com">Axiom Answers for Clients</a>{' '}
    or <a href="mailto:answers@axiomlaw.com">Axiom Answers for Talent</a> for
    assistance. Please note that this link will expire in 10 minutes.
  </CommonCardConfirmation>
);

export default TokenLogin;
