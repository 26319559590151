import React, { useEffect, useState } from 'react';

import { isVisible } from '../Visible/Visible';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { useBreakpoint } from '../../../hooks/use-breakpoint';

import { SidebarProps, SidedrawerUtil } from './sidedrawer-util';

export const Sidedrawer = ({ children }: { children: React.ReactNode }) => {
  const breakpoints = useBreakpoint();
  const [_, setLastUpdatedAt] = useState<Date>(null);
  useEffect(() => {
    const subscription = SidedrawerUtil.listen(() => {
      setLastUpdatedAt(new Date());
    });

    setLastUpdatedAt(new Date());

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (breakpoints.isSmallScreen) {
      if (SidedrawerUtil.isOpen()) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = null;
      }
    }
  }, [SidedrawerUtil.isOpen()]);

  const visibleSidedrawers = ['left', 'right'].reduce(
    (acc: Record<string, Array<SidebarProps>>, direction) => {
      acc[direction] = SidedrawerUtil.getAll()[direction].filter(
        sidedrawerConfig =>
          !sidedrawerConfig.only ||
          isVisible(sidedrawerConfig.only, breakpoints)
      );

      return acc;
    },
    {}
  );
  return (
    <div
      className={AttrsHelper.formatClassname(
        'sidedrawer-container',
        SidedrawerUtil.isOpen() && 'sidedrawer-open'
      )}
    >
      {['left', 'right'].map(direction => {
        const sidedrawers = visibleSidedrawers[direction];
        return (
          sidedrawers.length > 0 && (
            <div className={`sidedrawer-${direction}`} key={direction}>
              {sidedrawers.map(sidebar => (
                <div
                  className="sidedrawer-block"
                  key={sidebar.name}
                  data-test={sidebar.name}
                >
                  {sidebar.content}
                </div>
              ))}
            </div>
          )
        );
      })}

      <div
        className={AttrsHelper.formatClassname(
          'sidedrawer-wrapper-content',
          visibleSidedrawers.left.length &&
            `sidedrawer-left-${visibleSidedrawers.left.length}`,
          visibleSidedrawers.right.length &&
            `sidedrawer-right-${visibleSidedrawers.right.length}`
        )}
      >
        {children}
      </div>
    </div>
  );
};
