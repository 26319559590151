import React from 'react';
import { CandidatesConst } from '@axiom/const';

import { Gutter } from '../../layout/Gutter/Gutter';
import { Badge } from '../../element/Badge/Badge';
import { backgroundColorNames } from '../../../theme/axiom-theme';

const COLORS = {
  [CandidatesConst.Badges.Contractor]: 'green',
};

export type BadgesTypes = { badges: string[] };

export const CandidateBadges = ({ badges }: BadgesTypes) => {
  return badges?.length > 0 ? (
    <div data-test="BADGES_CONTAINER">
      {badges.map(badge => (
        <Gutter right="8px" bottom="6px" key={badge} as="span">
          <Badge
            name={badge}
            background={
              (COLORS[
                badge as keyof typeof CandidatesConst.Badges
              ] as typeof backgroundColorNames[keyof typeof backgroundColorNames]) ??
              backgroundColorNames.gray
            }
          >
            {badge}
          </Badge>
        </Gutter>
      ))}
    </div>
  ) : null;
};
