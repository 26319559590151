import crypto from 'crypto-js/sha256';
import { User } from '@axiom/validation';

type Payload = {
  visitor: {
    id?: string;
    role?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    apUserId?: string;
  };
  account: {
    id?: 'AxiomLaw';
  };
};

export const PendoUtil = {
  initializePendo({ id, roles, email, firstName, lastName }: User = {}) {
    // Pendo doesn't init every reload without the delay

    setTimeout(() => {
      if (window?.pendo) {
        const payload: Payload = {
          visitor: {
            id: id ? crypto(id).toString() : null,
            role: roles?.length > 0 ? roles[0] : null,
            firstName: firstName || null,
            lastName: lastName || null,
            email: email || null,
            apUserId: id || null,
          },
          account: {
            id: 'AxiomLaw',
          },
        };

        console.log('Initing Pendo As:', payload.visitor);
        window.pendo.initialize(payload);
      }
    }, 1000);
  },
};
