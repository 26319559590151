import React from 'react';
import bowser from 'bowser';
import { arrayOf, bool } from 'prop-types';

import { window } from '../../../utils/global';
import { ExperienceShape } from '../../../models/experience-model';
import { Header3 } from '../../content/Header3/Header3';
import { Text } from '../../content/Text/Text';
import { Experience } from '../Experience/Experience';
import { CertifiedLabel } from '../../general/CertifiedLabel/CertifiedLabel';

import { DSToggleButton } from './DSToggleButton';
import {
  DSCandidateExperiencesWrapper,
  DSCandidateExpTextExistsWrapper,
  DSCandidateExpTextWrapper,
  DSCandidateHighlight,
  DSCandidateSection,
} from './DSCandidateStyles';

function isUsingIe11(userAgent) {
  const browser = bowser.getParser(userAgent).getBrowser();
  return (
    browser.name === 'Internet Explorer' && parseInt(browser.version, 10) <= 11
  );
}

const DSExperienceView = (experience, showHighlightWrappers) => (
  <DSCandidateExpTextWrapper key={experience.id}>
    {experience.experienceVisible && showHighlightWrappers ? (
      <DSCandidateHighlight>
        <Experience experience={experience} />
      </DSCandidateHighlight>
    ) : (
      <Experience experience={experience} />
    )}
  </DSCandidateExpTextWrapper>
);

export class DSExperiences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isIe11: true,
      showAllExperiences: true,
    };
  }

  componentDidMount() {
    if (window.navigator && window.navigator.userAgent) {
      this.initShownExperiences();
    } else {
      window.addEventListener('DOMContentLoaded', this.initShownExperiences);
    }
  }

  initShownExperiences = () => {
    if (!isUsingIe11(window.navigator.userAgent)) {
      this.setState({
        isIe11: false,
        showAllExperiences: false,
      });
    }
  };

  toggleShowAllExperiences = () => {
    this.setState(state => ({
      showAllExperiences: !state.showAllExperiences,
    }));
  };

  render() {
    const {
      toggleShowAllExperiences,
      state: { showAllExperiences, isIe11 },
      props: { anonymous, experiences },
    } = this;

    const allExperiences = experiences.map(e => ({
      ...e,
      calculatedDisplayName: (() => {
        if (e.isAxiom && (anonymous || e.isConfidential)) {
          return 'Axiom Client';
        }
        if (e.isConfidential) {
          return 'Confidential Client';
        }
        return e.calculatedDisplayName;
      })(),
    }));

    if (allExperiences.length === 0) {
      return null;
    }

    const visibleExperiences = allExperiences.filter(e => e.experienceVisible);
    const otherExperiences = allExperiences.filter(e => !e.experienceVisible);

    const canToggleAllExperiences =
      !isIe11 && visibleExperiences.length !== allExperiences.length;

    const showHighlightWrappers =
      visibleExperiences.length !== allExperiences.length;

    const legend = allExperiences.some(e => e.isAxiom) ? (
      <CertifiedLabel title="Axiom Engagement" />
    ) : null;

    return (
      <DSCandidateSection borderless data-test="EXPERIENCE_CONTAINER">
        {visibleExperiences.length ? (
          <DSCandidateExperiencesWrapper>
            <Header3>Experience</Header3>

            <DSCandidateExpTextWrapper className="hide-print">
              <DSCandidateExpTextExistsWrapper>
                <Text subtle name="highlighted_Experiences_muted_text_part_one">
                  The experiences that are most relevant for your current needs
                  are highlighted below.
                </Text>

                {canToggleAllExperiences && (
                  <Text
                    subtle
                    name="highlighted_Experiences_muted_text_part_two"
                  >
                    You can view the complete experience by selecting Show All
                    Experiences.
                  </Text>
                )}

                {legend}
              </DSCandidateExpTextExistsWrapper>
            </DSCandidateExpTextWrapper>

            {visibleExperiences.map(exp =>
              DSExperienceView(exp, showHighlightWrappers)
            )}

            <div className="hide-print">
              {showAllExperiences &&
                otherExperiences.map(exp => DSExperienceView(exp))}

              {canToggleAllExperiences && (
                <DSToggleButton
                  expanded={showAllExperiences}
                  onClick={toggleShowAllExperiences}
                >
                  {showAllExperiences
                    ? 'Show Highlighted Experiences'
                    : 'Show All Experiences'}
                </DSToggleButton>
              )}
            </div>

            {otherExperiences.length > 0 && (
              <div className="show-print">
                {otherExperiences.map(exp => DSExperienceView(exp))}
              </div>
            )}
          </DSCandidateExperiencesWrapper>
        ) : (
          <>
            <Header3>Experience</Header3>
            {legend}
            {allExperiences.map(exp =>
              DSExperienceView(exp, showHighlightWrappers)
            )}
          </>
        )}
      </DSCandidateSection>
    );
  }
}

DSExperiences.defaultProps = {
  anonymous: false,
  experiences: [],
};

DSExperiences.propTypes = {
  anonymous: bool,
  experiences: arrayOf(ExperienceShape),
};
