import moment from 'moment';

import { UserConst } from './user';

// 3 months ahead in seconds from epoch
const threeMonths = () =>
  Math.trunc((moment().add(3, 'months').valueOf() - moment().valueOf()) / 1000);

export const AuthConst = {
  defaultJwtExpiration: '24h',
  roleJwtExpiration: {
    [UserConst.Roles.ClientUser]: '7d',
  },
  // PATCH /auth/tokenLogin/:id
  tokenLoginJwtExpiration: threeMonths(),
  FlowTypes: {
    Verify: 'Verify', // set new password
    Prompt: 'Prompt', // ask for existing password
  },
  LoginFlows: {
    Talent: 'Talent', // legacy login
    Client: 'Client', // client login
  },
} as const;

export type AuthConstFlowTypesKeyTypes = keyof typeof AuthConst.FlowTypes;
export type AuthConstFlowTypesValueTypes =
  typeof AuthConst.FlowTypes[AuthConstFlowTypesKeyTypes];
