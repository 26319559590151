import React, { Suspense as RSuspsense } from 'react';

import { Loader } from '../Loader/Loader';

type SuspenseProps = {
  children?: React.ReactNode;
  fallback?: React.ReactNode;
  pendingFallback?: React.ReactNode;
  isPending?: boolean;
  stretched?: boolean;
  scrollable?: boolean;
};
export const Suspense = ({
  children,
  fallback = <Loader type="spinner" />,
  pendingFallback,
  isPending = false,
  stretched = false,
  scrollable = false,
}: SuspenseProps) => {
  const styled: Record<string, string> = {
    position: 'relative',
  };
  if (stretched) {
    styled.height = '100%';
  }
  if (scrollable) {
    styled.overflowY = 'auto';
  }
  return (
    <div style={styled}>
      {isPending &&
        React.cloneElement(
          (pendingFallback || (
            <Loader type="spinner" expand="cover" />
          )) as React.ReactElement,
          {},
          children
        )}
      <RSuspsense fallback={fallback}>{children}</RSuspsense>
    </div>
  );
};
