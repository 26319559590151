import React, { Children } from 'react';

import { Grid } from '../Grid/Grid';
import { GridColumn, ResponsiveColumnOptions } from '../Grid/GridColumn';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import {
  ResponsiveConfig,
  ViewportName,
} from '../../../configs/responsive-config';

export type FluidButtonLayoutProps = {
  children?: React.ReactNode;
  className?: string;
  columnWidth?: 2 | 3 | 4 | 5;
  name?: string;
  stackableOn?: string | ViewportName[];
};

export const FluidButtonLayout = ({
  className,
  children,
  columnWidth,
  name,
  stackableOn,
}: FluidButtonLayoutProps) => {
  const arrayChildren = Children.toArray(children);
  const childCount = Children.count(children);
  const stacked = Array.isArray(stackableOn)
    ? stackableOn
    : ((stackableOn?.split(' ') || []) as ViewportName[]);

  const fillerColumnWidth =
    12 - columnWidth * childCount >= 0
      ? ((12 - columnWidth * childCount) as ResponsiveColumnOptions)
      : undefined;
  const equalColumnWidths = Math.floor(
    12 / childCount
  ) as ResponsiveColumnOptions;

  return (
    <div
      className={AttrsHelper.formatClassname(
        'fluid-button-layout',
        columnWidth ? 'withColumns' : 'withoutColumns',
        stacked.map(st => `stacked-${st}`).join(' '),
        className
      )}
      data-test={name}
    >
      {columnWidth && (
        <Grid
          reversed={
            stacked.length > 0
              ? `${stacked.join(' vertically ')} ${
                  stacked.length && 'vertically'
                }`
              : undefined
          }
        >
          <GridColumn
            only="largeScreen tablet"
            className="filler-column"
            widescreenWidth={fillerColumnWidth}
            desktopWidth={fillerColumnWidth}
            tabletWidth={fillerColumnWidth}
          />
          {arrayChildren.map((child, index) => (
            <GridColumn
              name="content-column"
              key={`child_${index + 1}`}
              widescreenWidth={
                ResponsiveConfig.hasWidescreen(stacked) ? 12 : columnWidth
              }
              desktopWidth={
                ResponsiveConfig.hasDesktop(stacked) ? 12 : columnWidth
              }
              tabletWidth={
                ResponsiveConfig.hasTablet(stacked) ? 12 : columnWidth
              }
              mobileWidth={
                ResponsiveConfig.hasMobile(stacked) ? 12 : equalColumnWidths
              }
            >
              {child}
            </GridColumn>
          ))}
        </Grid>
      )}
      {!columnWidth && children}
    </div>
  );
};
