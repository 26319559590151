import requestLib from 'superagent';
import FormData from 'form-data';

import {
  APIError,
  BadRequestError,
  AuthorizationError,
  AuthenticationError,
} from '../errors';

export const request = async (name, endpoint, method, body, headers = {}) => {
  try {
    const requestObj = requestLib[method.toLowerCase()](`${endpoint}`).set({
      ...(body instanceof FormData
        ? {}
        : { 'Content-Type': 'application/json' }),
      ...headers,
    });

    const response = await requestObj.send(body);
    return response.body;
  } catch (e) {
    const errorTypeExceptions = {
      BLOCKED_EMAIL: true,
    };

    if (!e || !e.response) {
      throw new Error(e);
    }

    const { response } = e;

    const { body: errBody } = response;
    const obj = {
      name,
      endpoint,
      method,
      body: errBody || {},
      headers,
      response,
    };

    switch (response.status) {
      case 400:
        // eslint-disable-next-line no-case-declarations
        const errorException = errBody?.errors?.find(
          error => errorTypeExceptions[error.errorType]
        );

        if (!errorException) throw new BadRequestError(obj);
        return errorException;
      case 403:
        throw new AuthenticationError(obj);
      case 401:
        throw new AuthorizationError(obj);
      default:
        throw new APIError(obj);
    }
  }
};
