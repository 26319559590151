/** Event */

const BACKGROUND_CHECK_COMPLETED = 'Background Check Completed';
const WARMING = 'Warming';
const COOLING = 'Cooling';
const INTERVIEW_PREP = 'Interview Prep';
const SUBMISSION = 'Submission';
const OPPORTUNITY_INTERVIEW = 'Opportunity Interview';
const CONFLICT_CHECK = 'Conflict Check';
const GOOD_STANDING_CHECK = 'Good Standing Check';
const ARCHIVED_FEEDBACK = 'Archived Feedback';
const FEEDBACK_FROM_CLIENT = 'Feedback from Client';
const WRAP_MEETING = 'Wrap Meeting';
const TALENT_UPDATE = 'Talent Update';
const TALENT_CHECK_IN = 'Talent Check In';
const REFERENCE_CHECK = 'Reference Check';
const INTRO_CALL_SCREEN = 'Intro Call/Screen';
const COMPENSATION_PTO = 'Compensation/PTO';
const NEXT_STEPS_EMAIL = 'Next Steps Email';
const OTHER = 'Other';
const TALENT_OPT_IN_DETAIL = 'Talent Opt In Detail';
const TALENT_OPT_OUT_DETAIL = 'Talent Opt Out Detail';
const OUTBOUND_EMAIL = 'Outbound - Email';
const INTERVIEW_ONE = 'Interview #1';
const INTERVIEW_TWO = 'Interview #2';
const INVITATION_TO_WAITLIST = 'Invitation to Waitlist';
const SOURCE_LINKEDIN = 'Sourced - LinkedIn';
const SOURCE_RESERVOIR = 'Sourced - Reservoir';
const SOURCE_OTHER = 'Sourced - Other';
const EXPRESSED_INTEREST = 'Expressed Interest';
const INTERVIEW_FEEDBACK = 'Interview Feedback';
const TALENT_OPTED_OUT = 'Talent Opted Out';
const TALENT_WARMED = 'Talent Warmed';
const BAR_STATUS_EXCEPTION = 'Bar Status Exception';

const EventPurposesUserSelected = {
  BarStatusException: BAR_STATUS_EXCEPTION,
  BackgroundCheckCompleted: BACKGROUND_CHECK_COMPLETED,
  CompensationPto: COMPENSATION_PTO,
  ConflictCheck: CONFLICT_CHECK,
  FeedbackFromClient: FEEDBACK_FROM_CLIENT,
  GoodStandingCheck: GOOD_STANDING_CHECK,
  InterviewFeedBack: INTERVIEW_FEEDBACK,
  InterviewPrep: INTERVIEW_PREP,
  InvitationToWaitlist: INVITATION_TO_WAITLIST,
  Other: OTHER,
  ReferenceCheck: REFERENCE_CHECK,
  TalentCheckIn: TALENT_CHECK_IN,
  TalentOptedOut: TALENT_OPTED_OUT,
  TalentUpdate: TALENT_UPDATE,
  TalentWarmed: TALENT_WARMED,
};

const EventPurposesAutomated = {
  ArchivedFeedback: ARCHIVED_FEEDBACK, // Not in list
  Cooling: COOLING,
  ExpressedInterest: EXPRESSED_INTEREST, // Not in list
  InterviewOne: INTERVIEW_ONE,
  InterviewTwo: INTERVIEW_TWO,
  IntroCallScreen: INTRO_CALL_SCREEN,
  NextStepsEmail: NEXT_STEPS_EMAIL,
  OpportunityInterview: OPPORTUNITY_INTERVIEW,
  OutboundEmail: OUTBOUND_EMAIL, // Not in list
  SourcedLinkedin: SOURCE_LINKEDIN, // Not in list
  SourcedOther: SOURCE_OTHER, // Not in list
  SourcedReservoir: SOURCE_RESERVOIR, // Not in list
  Submission: SUBMISSION,
  TalentOptInDetail: TALENT_OPT_IN_DETAIL,
  TalentOptOutDetail: TALENT_OPT_OUT_DETAIL,
  Warming: WARMING,
  WrapMeeting: WRAP_MEETING, // Not in list
};

export const EventsConst = {
  Purposes: {
    ...EventPurposesUserSelected,
    ...EventPurposesAutomated,
  },
  UserPurposes: EventPurposesUserSelected,
  AutomatedPurposes: EventPurposesAutomated,
  Types: {
    Call: 'Call',
    Email: 'Email',
    Interview: 'Interview',
    InPerson: 'In Person',
    Note: 'Note',
    Video: 'Video',
  },
  AllowedFileExtensions: [
    'pdf',
    'png',
    'jpeg',
    'jpg',
    'json',
    'gif',
    'xlsx',
    'svg',
    'html',
    'xml',
    'shtml',
    'avi',
    'csv',
    'docx',
    'eps',
    'htm',
    'mp4',
    'msg',
    'pptx',
    'shtm',
    'swf',
    'tiff',
    'txt',
    'xhtml',
    'xls',
  ],
  AllowedFileMimeTypes: [
    'application/pdf',
    'image/png',
    'image/jpeg',
    'application/json',
    'image/gif',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/svg+xml',
    'text/html',
    'application/xml',
    'text/xml',
    'text/x-server-parsed-html',
    'text/asp',
    'video/x-msvideo',
    'text/csv',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/postscript',
    'image/x-eps',
    'video/mp4',
    'application/vnd.ms-outlook',
    'application/x-msg',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'wwwserver/html-ssi',
    'application/x-shockwave-flash',
    'image/tiff',
    'text/plain',
    'application/plain',
    'application/xhtml+xml',
    'application/vnd.ms-excel',
  ],
};

// eslint-disable-next-line import/prefer-default-export
export const Event = {
  PURPOSE_OPTIONS: {
    WARMING,
    COOLING,
    INTERVIEW_PREP,
    SUBMISSION,
    OPPORTUNITY_INTERVIEW,
    CONFLICT_CHECK,
    GOOD_STANDING_CHECK,
    ARCHIVED_FEEDBACK,
    FEEDBACK_FROM_CLIENT,
    WRAP_MEETING,
    TALENT_UPDATE,
    TALENT_CHECK_IN,
    REFERENCE_CHECK,
    INTRO_CALL_SCREEN,
    COMPENSATION_PTO,
    NEXT_STEPS_EMAIL,
    OTHER,
    INTERVIEW_FEEDBACK,
    TALENT_OPTED_OUT,
    TALENT_WARMED,
    BAR_STATUS_EXCEPTION,
  },
  LAST_CHECK_IN_VALID_PURPOSES: {
    FEEDBACK_FROM_CLIENT,
    WRAP_MEETING,
    TALENT_CHECK_IN,
    SUBMISSION,
  },

  /** field names */
  ATTACHMENT_KEY: 'attachmentKey',
  ATTACHMENT_NAME: 'attachmentName',
  BULLHORN_NOTE_ID: 'bullhornNoteId',
  CANDIDATE_ID: 'candidateId',
  CREATED_AT: 'createdAt',
  DESCRIPTION: 'description',
  EMAIL_ADDRESS: 'emailAddresses',
  ID: 'id',
  LAST_AUTHORED_BY: 'lastAuthoredBy',
  LAST_UPDATED_BY: 'lastUpdatedBy',
  PURPOSE: 'purpose',
  OPPORTUNITY_ID: 'opportunityId',
  SALESFORCE_EVENT_ID: 'salesforceEventId',
  SCHEDULED_DATE: 'scheduledDate',
  TYPE: 'type',
  UPDATED_AT: 'updatedAt',
};
