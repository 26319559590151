import styled, { css } from 'styled-components';
import { Card } from 'reactstrap';
import { Header1, MediaQueryUtil } from '@axiom/ui';

import { PALETTE } from '../../themes/constants';

const { grays } = PALETTE;

export const CCPageWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
`;

export const CCHeader = styled.div`
  text-align: center;
`;

export const CCIconWrap = styled.div`
  margin-top: 2.1875rem;
  margin-bottom: 0.625rem;

  ${MediaQueryUtil.mobileOnly(css`
    margin-top: 4.028125rem;
  `)}
`;

export const CCTitle = styled(Header1)`
  /* this doubles the classname, increasing the specificity */
  && {
    font-size: 2.125rem;
  }
`;

export const CCWrapper = styled(Card).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    prop !== 'hasFooter' && prop !== 'isWide' && defaultValidatorFn(prop),
})`
  width: ${({ isWide }) => (isWide ? '52.8125rem' : '33.75rem')};
  max-width: 90%;
  min-height: 40rem;
  border-radius: 0.5rem;
  padding-top: 2rem;
  padding-left: 2.8125rem;
  padding-right: 2.1825rem;
  overflow: hidden;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  justify-content: space-between;

  ${MediaQueryUtil.mobileOnly(css`
    width: auto;
    max-width: 33.75rem;
  `)}

  ${MediaQueryUtil.mobileOnly(css`
    width: 90%;
    max-width: none;
    padding-left: 15%;
    padding-right: 15%;
  `)}
  
  ${MediaQueryUtil.mobileOnly(css`
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 5%;
  `)}
`;

export const CCGoBackWrapper = styled.div`
  height: 1.5rem; /* matches line height */
`;

export const CCBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.isCentered && 'center'};
  min-height: ${props => props.isCentered && '33rem'};
  padding-bottom: ${props => props.isCentered && '1.5rem'};
`;

export const CCFooter = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid ${grays.gray12};
    background-color: ${grays.gray10};
    z-index: -1;
    // overflow: hidden on parent will hide this.
    width: 100vw;
  }

  z-index: 1;
  height: 6.25rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  margin-top: 2rem;

  ${MediaQueryUtil.mobileOnly(css`
    height: auto;
    padding: 1.625rem 1.566875rem 1.6875rem 1.818125rem;
    font-size: 1rem;
  `)}
`;
