import { FormLoginStore } from './form-login-store';
import { FormManualLoginStore } from './form-manual-login-store';
import { FormRequestPasswordStore } from './form-request-password-store';
import { FormResetPasswordStore } from './form-reset-password-store';
import { FormSignUpStore } from './form-sign-up-store';
import { PreloadedFeatureFlagsStore } from './preloaded-feature-flags-store';
import { TokenLoginStore } from './token-login-store';
import { UserStore } from './user-store';
import { RequestPasswordStore } from './request-password-store';
import { TermsStore } from './terms-store';

export const stores = {
  FEATURE_FLAGS: PreloadedFeatureFlagsStore,
  FORM_LOGIN: FormLoginStore,
  FORM_MANUAL_LOGIN: FormManualLoginStore,
  FORM_REQUEST_PASSWORD: FormRequestPasswordStore,
  FORM_RESET_PASSWORD: FormResetPasswordStore,
  FORM_SIGN_UP: FormSignUpStore,
  TOKEN_LOGIN: TokenLoginStore,
  REQUEST_PASSWORD_STORE: RequestPasswordStore,
  USER: UserStore,
  TERMS_STORE: TermsStore,
};

export const initialState = {};

// This whole if statement should automatically be removed during a production build, since
// it only applies to development mode.
if (module.hot) {
  // This solves the problem of GenericStores losing their identities and initialization
  // during hot module reload in development. The problem is that, when the modules are
  // reloaded, the store objects (like FormLoginStore) are re-created. However, the redux store
  // isn't reinitialized from scratch, i.e. store-manager's initializeStore() is not called.
  // This is correct behavior, we don't want to reinitialize the entire store, but it means
  // that the new GenericStore objects never get initializeNamespace and initializeDispatch
  // called on them, so their internal states aren't correct. To solve this, we need intercept
  // the reload and save the old internal states of those objects, then restore them when
  // we are reinitialized.

  // This logic is put in this file because, since this file imports all the GenericStores
  // in the project, it will itself be reloaded any time one of the stores is reloaded. The
  // alternative would be to duplicate (a slightly modified version of) this logic into
  // every file that creates a GenericStore.

  // dispose is called when we are about to be unloaded as part of a hot reload. We can
  // write values into the data object and the reload runtime will persist them into the
  // new version of this module that is being loaded.
  module.hot.dispose(data => {
    Object.keys(stores).forEach(namespace => {
      data[namespace] = { ...stores[namespace] };
    });
  });

  // data that was persisted from the previous version of our module shows up in module.hot.data,
  // so if this has content then it means we are coming after some older version of this module
  // that we replaced during a hot reload. We should attempt to restore any information present
  // in the data that was provided to us, as long as the store doesn't already have its own
  // version.
  if (module.hot.data) {
    Object.keys(module.hot.data).forEach(namespace => {
      const store = stores[namespace];
      const data = module.hot.data[namespace];
      Object.keys(data).forEach(key => {
        store[key] = store[key] || data[key];
      });
    });
  }
}
