import React from 'react';
import { string, oneOf } from 'prop-types';
import { WarningIcon } from '@axiom/ui';

import { PALETTE } from '../../themes/constants';

import { ErrorBlurbWrapper } from './ErrorBlurbStyles';

const { white } = PALETTE.grays;

export const ErrorBlurb = ({ errorMessage, align }) => (
  <ErrorBlurbWrapper align={align}>
    <WarningIcon backgroundColor={PALETTE.colors.axiomOrange} color={white} />
    <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
  </ErrorBlurbWrapper>
);

ErrorBlurb.propTypes = {
  errorMessage: string,
  align: oneOf(['left', 'center']),
};

ErrorBlurb.defaultProps = {
  align: 'left',
  errorMessage: 'An error occurred. Please refresh.',
};
