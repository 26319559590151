// eslint-disable-next-line import/prefer-default-export

export const LANGUAGE_SKILLS_KEYS = {
  SPEAKING: 'speaking',
  READING: 'reading',
  WRITING: 'writing',
};

export const LANGUAGE_PROFICIENCIES_KEYS = {
  BASIC: 'basic',
  PROFICIENT: 'proficient',
  FLUENT: 'fluent',
};

export const LanguageConst = {
  LANGUAGE_PROFICIENCIES: {
    [LANGUAGE_PROFICIENCIES_KEYS.BASIC]: 'Conversational',
    [LANGUAGE_PROFICIENCIES_KEYS.PROFICIENT]: 'Business Proficient',
    [LANGUAGE_PROFICIENCIES_KEYS.FLUENT]: 'Native or Bilingual',
  },
  LANGUAGE_SKILLS: {
    [LANGUAGE_SKILLS_KEYS.SPEAKING]: 'Speaking',
    [LANGUAGE_SKILLS_KEYS.READING]: 'Reading',
    [LANGUAGE_SKILLS_KEYS.WRITING]: 'Writing',
  },
  LANGUAGE_CEFR_LEVELS: {
    A1: 'A1',
    A2: 'A2',
    B1: 'B1',
    B2: 'B2',
    C1: 'C1',
    C2: 'C2',
  },
};
