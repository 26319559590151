// https://stackoverflow.com/questions/7944460/detect-safari-browser

export const browsers = {
  SAFARI: 'SAFARI',
  FIREFOX: 'FIREFOX',
  INTERNET_EXPLORER: 'INTERNET EXPLORER',
};

export const isSafari = () => {
  const { userAgent, vendor } = navigator;

  return (
    vendor?.indexOf('Apple') > -1 &&
    userAgent?.indexOf('CriOS') === -1 &&
    userAgent?.indexOf('FxiOS') === -1
  );
};

export const isFireFox = () => {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
};

export const isIE = () => {
  const { userAgent } = navigator;
  return (
    userAgent.toLowerCase().indexOf('msie') > -1 ||
    userAgent.toLowerCase().indexOf('trident') > -1
  );
};

export const DetectBrowserUtil = {
  isSafari,
  isIE,
  isFireFox,
};
