import memoize from 'lodash/memoize';

export const SkillsUtil = {
  toTree: memoize((skills, emptyLeafArray = true) => {
    const dedupped = skills.reduce((data, item) => {
      const exists = data.find(x => x.id === item.id);

      return exists ? data : data.concat([item]);
    }, []);

    const arrayToTree = (nodes, parentId) => {
      const arrayToReturn = [];

      nodes.forEach(node => {
        if (node.parentId === parentId) {
          const children = arrayToTree(nodes, node.id);
          arrayToReturn.push({
            id: node.id,
            name: node.name,
            type: node.type,
            ...((children?.length || emptyLeafArray) && { children }),
          });
        }
      });
      return arrayToReturn;
    };

    return arrayToTree(dedupped, null);
  }),

  // returns dedupped category > skills three
  categorySkills: memoize(skills => {
    const tree = SkillsUtil.toTree(skills);

    const properStructure = tree.map(trunk => {
      const data = {
        id: trunk.id,
        name: trunk.name,
        children: [],
      };

      trunk.children.forEach(branch => {
        branch.children.forEach(leaf => {
          data.children.push({
            id: leaf.id,
            name: leaf.name,
          });
        });
      });

      return data;
    });

    return properStructure;
  }),

  onlySkills: memoize(skills => {
    const tree = SkillsUtil.toTree(skills);
    const leaves = [];

    tree.forEach(trunk => {
      trunk.children.forEach(branch => {
        branch.children.forEach(leaf => {
          leaves.push(leaf);
        });
      });
    });

    return [];
  }),

  toSkillsTree: memoize(skills => {
    const toReturn = [];

    // converting skills tree into a flat list, optimization for DropdownTree
    SkillsUtil.toTree(skills).forEach(category => {
      category.children.forEach(subCategory => {
        toReturn.push(
          ...subCategory.children.map(item => ({
            id: item.id,
            name: item.name,
            type: item.type,
            category: category.name,
            subCategory: subCategory.name,
          }))
        );
      });
    });

    return toReturn;
  }),
};
