import { useEffect, useState } from 'react';

import { windowResizeEvent } from './use-window-resize';

const tabletMinWidth = 768;
const desktopMinWidth = 1025;
const widescreenMinWidth = 1920;

const breakpointTypes = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
  widescreen: 'widescreen',
};

const screenBreakpointBuckets = {
  smallScreen: [breakpointTypes.mobile, breakpointTypes.tablet],
  largeScreen: [breakpointTypes.desktop, breakpointTypes.widescreen],
};

const getBreakpoint = (windowWidth: number) => {
  switch (true) {
    case windowWidth < tabletMinWidth:
      return breakpointTypes.mobile;
    case windowWidth < desktopMinWidth:
      return breakpointTypes.tablet;
    case windowWidth < widescreenMinWidth:
      return breakpointTypes.desktop;
    case windowWidth >= widescreenMinWidth:
      return breakpointTypes.widescreen;
    default:
      return breakpointTypes.desktop;
  }
};

const getBreakpoints = (windowWidth: number) => {
  const breakpoint = getBreakpoint(windowWidth);
  return {
    isMobile: breakpoint === breakpointTypes.mobile,
    isTablet: breakpoint === breakpointTypes.tablet,
    isDesktop: breakpoint === breakpointTypes.desktop,
    isWidescreen: breakpoint === breakpointTypes.widescreen,
    isSmallScreen: screenBreakpointBuckets.smallScreen.includes(breakpoint),
    isLargeScreen: screenBreakpointBuckets.largeScreen.includes(breakpoint),
    breakpoint,
  };
};

export const useBreakpoint = () => {
  const [breakpoints, setBreakpoints] = useState(
    getBreakpoints(window.innerWidth)
  );

  useEffect(() => {
    const subscription = windowResizeEvent.subscribe(dimensions => {
      setBreakpoints(breaks => {
        /**
         * We're using a setState function here because otherwise if you just ref the state object outside this rxjs event,
         * the object pointer might be old. This ensure we have the correct up-to-date state to work with
         * when figuring out if we're in a new breakpoint.
         */
        if (breaks.breakpoint !== getBreakpoint(dimensions.width)) {
          return getBreakpoints(dimensions.width);
        }
        return breaks;
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    isMobile: breakpoints.isMobile,
    isTablet: breakpoints.isTablet,
    isDesktop: breakpoints.isDesktop,
    isWidescreen: breakpoints.isWidescreen,
    isSmallScreen: breakpoints.isSmallScreen,
    isLargeScreen: breakpoints.isLargeScreen,
  };
};
