import { CandidateOpportunitiesConst } from './candidate-opportunities';
import { CountryCodesConst } from './countryCodes';

const { CountryCodesAbbreviations } = CountryCodesConst;

/** Candidate */
export const DefaultWeeklyHours = {
  maxHours: 40,
  minHours: 30,
  dailyHours: 8,
} as const;
Object.freeze(DefaultWeeklyHours);

const candidateFreeFilterColumns = [
  'compensation',
  'practiceStartYear',
  'weeklyAvailability',
];

const TALENT_VIEW_SORT_COLUMNS = [
  'relevance',
  'addressCity',
  'calculatedLastName',
  'ownerUser.lastName',
  'profileStatus',
  'soonestEngagementEndDate',
  'updatedAt',
  'weeklyAvailability',
  'compensation',
];

const BENCH_VIEW_SORT_COLUMNS = [
  ...TALENT_VIEW_SORT_COLUMNS,
  'alphabeticallySmallestEngagementAccountName',
];

export const Candidate = {
  FILTER_COLUMNS: [
    ...candidateFreeFilterColumns,
    'address1',
    'address2',
    'addressCity',
    'addressCountry',
    'addressCountryCode',
    'addressState',
    'addressZip',
    'integrationId',
    'calculatedDisplayName',
    'calculatedFirstName',
    'calculatedLastName',
    'displayFirstName',
    'displayLastName',
    'employeeType',
    'firstName',
    'furthestCandidacyStatus',
    'homeOfficeId',
    'homePhone',
    'lastName',
    'lawSchool',
    'middleName',
    'mobilePhone',
    'occupationType',
    'personalEmail',
    'profileStatus',
    'resume',
    'workEmail',
  ],
  FREE_FILTER_COLUMNS: candidateFreeFilterColumns,
  SORT_COLUMNS: [
    ...new Set([...TALENT_VIEW_SORT_COLUMNS, ...BENCH_VIEW_SORT_COLUMNS]),
  ],
  SORT_COLUMNS_NAMES_MAP: {
    calculatedLastName: 'Talent Name',
    profileStatus: 'Employment Status',
    addressCity: 'Residence',
    ownerLastName: 'Talent Owner',
    updatedAt: 'Last Activity Date',
    soonestEngagementEndDate: 'Roll Off Date',
    alphabeticallySmallestEngagementAccountName: 'Account Name',
  },
  TALENT_VIEW_SORT_COLUMNS,
  BENCH_VIEW_SORT_COLUMNS,

  /** field names */
  ACTIVITY_LOG: 'activityLog',
  ADDRESS_1: 'address1',
  ADDRESS_2: 'address2',
  ADDRESS_CITY: 'addressCity',
  ADDRESS_COUNTRY: 'addressCountry',
  ADDRESS_COUNTRY_CODE: 'addressCountryCode',
  ADDRESS_DISPLAY_NAME: 'addressDisplayName',
  ADDRESS_STATE: 'addressState',
  ADDRESS_ZIP: 'addressZip',
  ALPHABETICALLY_SMALLEST_ENGAGEMENT_ACCOUNT_NAME:
    'alphabeticallySmallestEngagementAccountName',
  ALPHABETICALLY_SMALLEST_ENGAGEMENT_END_DATE:
    'alphabeticallySmallestEngagementEndDate',
  AVAILABILITY: 'availability',
  AVAILABILITY_NOTES: 'availabilityNotes',
  AVAILABILITY_PREFERENCES_UPDATED_AT: 'availabilityPreferencesUpdatedAt',
  AVAILABILITY_PREFERENCES_UPDATED_BY: 'availabilityPreferencesUpdatedBy',
  AVAILABILITY_PREFERENCES_UPDATED_BY_TYPE:
    'availabilityPreferencesUpdatedByType',
  AXIOM_EMPLOYEE_TYPE: 'axiomEmployeeType',
  BADGES: 'badges',
  BARRED_LOCATIONS: 'barredLocations',
  BULLHORN_ID: 'bullhornId',
  CALCULATED_DESIRED_WEEKLY_HOURS: 'calculatedDesiredWeeklyHours',
  CALCULATED_DISPLAY_NAME: 'calculatedDisplayName',
  CALCULATED_FIRST_NAME: 'calculatedFirstName',
  CALCULATED_LAST_NAME: 'calculatedLastName',
  CANDIDACY_ENGAGEMENTS: 'candidacyEngagements',
  CANDIDACY_LIST: 'candidacyList',
  COLLABORATORS: 'collaborators',
  COLLABORATOR_ID: 'collaboratorId',
  COMPENSATION: 'compensation',
  CREATED_AT: 'createdAt',
  DAYS_BEACHED: 'daysBeached',
  DAYS_DESIRED_REMOTE: 'daysDesiredRemote',
  DAYS_REQUIRED_REMOTE: 'daysRequiredRemote',
  DAYS_SINCE_STATUS_CHANGE: 'daysSinceStatusChange',
  DAYS_WILLING_TO_COMMUTE: 'daysWillingToCommute',
  DESIRED_COMMUTE: 'desiredCommute',
  DESIRED_ENGAGEMENT_SPECIFIC_COMPENSATION:
    'desiredEngagementSpecificCompensation',
  DISPLAY_FIRST_NAME: 'displayFirstName',
  DISPLAY_LAST_NAME: 'displayLastName',
  EMPLOYEE_TYPE: 'employeeType',
  EXPERIENCE: 'experience',
  EXPERIENCES: 'experiences',
  FIRST_NAME: 'firstName',
  FURTHEST_CANDIDACY_STATUS: 'furthestCandidacyStatus',
  FURTHEST_CANDIDACY_STATUS_UPDATED_AT: 'furthestCandidacyStatusUpdatedAt',
  PRACTICE_START_YEAR: 'practiceStartYear',
  HOME_OFFICE_ID: 'homeOfficeId',
  HOME_PHONE: 'homePhone',
  HOURLY_COMPENSATION: 'hourlyCompensation',
  ID: 'id',
  IS_OPEN_TO_ADHOC: 'isOpenToAdHoc',
  IS_OPEN_TO_COMMUTE: 'isOpenToCommute',
  IS_OPEN_TO_FULL_TIME: 'isOpenToFullTime',
  IS_OPEN_TO_PART_TIME: 'isOpenToPartTime',
  IS_OPEN_TO_REMOTE: 'isOpenToRemote',
  LANGUAGE_CEFR: 'languageCEFR',
  LANGUAGES: 'languages',
  LAST_NAME: 'lastName',
  LAST_UPDATED_BY: 'lastUpdatedBy',
  LATEST_ENGAGEMENT_ACCOUNT_NAME: 'latestEngagementAccountName',
  LATEST_ENGAGEMENT_END_DATE: 'latestEngagementEndDate',
  LAW_SCHOOL: 'lawSchool',
  MAX_MINUTES_COMMUTE_ONE_WAY: 'maxMinutesCommuteOneWay',
  MIDDLE_NAME: 'middleName',
  MOBILE_PHONE: 'mobilePhone',
  NOTICE_PERIOD: 'noticePeriod',
  OCCUPATION_TYPE: 'occupationType',
  OWNER_FULL_NAME: 'ownerFullName',
  OWNER_USER_ID: 'ownerUserId',
  PART_TIME_HOURS_MONDAY: 'partTimeHoursMon',
  PART_TIME_HOURS_TUESDAY: 'partTimeHoursTue',
  PART_TIME_HOURS_WEDNESDAY: 'partTimeHoursWed',
  PART_TIME_HOURS_THURSDAY: 'partTimeHoursThu',
  PART_TIME_HOURS_FRIDAY: 'partTimeHoursFri',
  PERSONAL_EMAIL: 'personalEmail',
  PRACTICE_AREA: 'practiceArea',
  PRACTICE_AREA_ID: 'practiceAreaId',
  PREF_FULL_TIME: 'prefFullTime',
  PREF_PART_TIME_HOURS_MON: 'prefPartTimeHoursMon',
  PREF_PART_TIME_HOURS_TUE: 'prefPartTimeHoursTue',
  PREF_PART_TIME_HOURS_WED: 'prefPartTimeHoursWed',
  PREF_PART_TIME_HOURS_THU: 'prefPartTimeHoursThu',
  PREF_PART_TIME_HOURS_FRI: 'prefPartTimeHoursFri',
  PREF_AD_HOC: 'prefAdHoc',
  PREF_REMOTE: 'prefRemote',
  PREF_REMOTE_WILLING_DAYS: 'prefRemoteWillingDays',
  PREF_REMOTE_REQUIRED_DAYS: 'prefRemoteRequiredDays',
  PREF_COMMUTE: 'prefCommute',
  PREF_COMMUTE_WILLING_DAYS: 'prefCommuteWillingDays',
  PREF_COMMUTE_MAX_ONE_WAY_MINUTES: 'prefCommuteMaxOneWayMinutes',
  PREFERRED_CONTACT_METHOD: 'preferredContactMethod',
  PROFILE_STATUS: 'profileStatus',
  PROFILE_STATUS_UPDATED_AT: 'profileStatusUpdatedAt',
  RESUME: 'resume',
  SOONEST_ENGAGEMENT_ACCOUNT_NAME: 'soonestEngagementAccountName',
  SOONEST_ENGAGEMENT_END_DATE: 'soonestEngagementEndDate',
  TAGS: 'tags',
  SKILLS: 'skills',
  UPDATED_AT: 'updatedAt',
  WEEKLY_AVAILABILITY: 'weeklyAvailability',
  WORK_EMAIL: 'workEmail',
  YEARS_EXPERIENCE: 'yearsExperience',
  YEARS_OF_EXPERIENCE: 'yearsOfExperience',
} as const;
Object.freeze(Candidate);

const { CandidateStatuses, CandidateStatusLabels, OppCandMlRecStatuses } =
  CandidateOpportunitiesConst;

const ProfileStatuses = {
  Active: 'Active',
  Alum: 'Alum',
  AlumDNR: 'Alum DNR',
  Beach: 'Beach',
  Certifying: 'Certifying',
  Idle: 'Idle',
  InDiligence: 'In Diligence',
  NewLead: 'New Lead',
  PendingActive: 'Pending Active',
  PendingAlum: 'Pending Alum',
  PendingAlumDNR: 'Pending Alum DNR',
  PendingBeach: 'Pending Beach',
  Rejected: 'Rejected',
  Reservoir: 'Reservoir',
  Waitlist: 'Waitlist',
} as { [key: string]: string };

const SortedProfileStatuses = [
  ProfileStatuses.NewLead,
  ProfileStatuses.Reservoir,
  ProfileStatuses.Certifying,
  ProfileStatuses.InDiligence,
  ProfileStatuses.Waitlist,
  ProfileStatuses.PendingActive,
  ProfileStatuses.Active,
  ProfileStatuses.PendingBeach,
  ProfileStatuses.Beach,
  ProfileStatuses.Idle,
  ProfileStatuses.PendingAlum,
  ProfileStatuses.Alum,
  ProfileStatuses.Rejected,
  ProfileStatuses.AlumDNR,
  ProfileStatuses.PendingAlumDNR,
] as string[];

const ProfileStatusLists = {
  allCertifiedTalent: {
    name: 'All Certified Talent',
    value: [
      ProfileStatuses.Active,
      ProfileStatuses.PendingActive,
      ProfileStatuses.Beach,
      ProfileStatuses.PendingBeach,
      ProfileStatuses.Waitlist,
      ProfileStatuses.InDiligence,
    ],
  },
  allProspects: {
    name: 'All Prospects',
    value: [
      ProfileStatuses.NewLead,
      ProfileStatuses.Reservoir,
      ProfileStatuses.Certifying,
    ],
  },
};

const EmploymentStatuses = {
  Active: 'Active',
  Beach: 'Beach',
  FormerContact: 'Former Contact',
  OnLeave: 'On Leave',
  PendingBeach: 'Pending Beach',
  PendingHire: 'Pending Hire',
  PendingRetire: 'Pending Retire',
  PendingReturn: 'Pending Return',
  PreHire: 'Pre-Hire',
  Retired: 'Retired',
  RescindedHire: 'Rescinded Hire',
};

export const OccupationTypes = {
  REVIEWER: 'Reviewer',
  PARALEGAL: 'Paralegal',
  LEGAL_SUPPORT: 'Legal Support',
  lawyer: 'Lawyer',
} as const;
export const occupationTypes = Object.values(OccupationTypes);
Object.freeze(occupationTypes);

export const CandidatesConst = {
  Unavailable: 'unavailable',
  FullTime: 'fullTime',
  PartTime: 'partTime',
  ProfileStatuses,
  ProfileStatusLists,
  EmploymentStatuses,
  SortedProfileStatuses,
  WorkingEmploymentStatuses: [
    EmploymentStatuses.Active,
    EmploymentStatuses.Beach,
    EmploymentStatuses.OnLeave,
    EmploymentStatuses.PendingHire,
    EmploymentStatuses.PendingRetire,
    EmploymentStatuses.PendingReturn,
  ],
  ValidFulfillmentCompletionStatuses: [
    ProfileStatuses.Waitlist,
    ProfileStatuses.PendingActive,
    ProfileStatuses.Active,
    ProfileStatuses.PendingBeach,
    ProfileStatuses.Beach,
  ],
  StatusInvalidForCompletionMessage:
    'Selected Talent is not eligible for fulfillment due to their profile status.',
  // Listener api call to `GET /candidates/:id`
  Expansions: {
    engagedAccountInfo: 'engagedAccountInfo',
    baseCandidateInfo: 'baseCandidateInfo',
    candidateES: 'candidateES',
  },
  ExpansionAttrs: {
    candidateOpportunity: ['candidateStatus', 'opportunityId'],
    candidateAccount: ['isHiddenExternal', 'calculatedNotes'],
  },

  // CandidateStatuses possible for furthestCandidacy in the furthest order
  // Note: needs to be an array so we can assure ordering
  FurthestCandidacyStatuses: [
    CandidateStatuses.RepBioShare,
    CandidateStatuses.ShortList,
    CandidateStatuses.Warmed,
    CandidateStatuses.WarmedUnsure,
    CandidateStatuses.WarmedYes,
    CandidateStatuses.Submitted,
    CandidateStatuses.Interviewing,
    CandidateStatuses.Selected,
  ],
  FurthestCandidacyStatusOptions: {
    [CandidateStatuses.RepBioShare]: CandidateStatusLabels.RepBioShare,
    [CandidateStatuses.ShortList]: CandidateStatusLabels.ShortList,
    [CandidateStatuses.Warmed]: CandidateStatusLabels.Warmed,
    [CandidateStatuses.WarmedUnsure]: CandidateStatusLabels.WarmedUnsure,
    [CandidateStatuses.WarmedYes]: CandidateStatusLabels.WarmedYes,
    [CandidateStatuses.Submitted]: CandidateStatusLabels.Submitted,
    [CandidateStatuses.Interviewing]: CandidateStatusLabels.Interviewing,
    [CandidateStatuses.Selected]: CandidateStatusLabels.Selected,
  },
  EmployeeTypes: {
    RegularEligible: 'Regular (Eligible)',
    RegularNonEligible: 'Regular (Non-Eligible)',
    FixedTermEligible: 'Fixed Term (Eligible)',
    FixedTermNonEligible: 'Fixed Term (Non-Eligible)',
    Consultant: 'Consultant / 1099',
    Temporary: 'Temporary',
    W2: 'W2',
  },
  availabilityPreferencesUpdatedByTypes: {
    Talent: 'TALENT',
    HQ: 'HQ',
  },
  TerminationReasons: {
    InvoluntaryNoRegret: 'Involuntary non-regrettable',
    InvoluntaryRegrettable: 'Involuntary regrettable',
    VoluntaryNoRegret: 'Voluntary non-regrettable',
    VoluntaryRegrettable: 'Voluntary regrettable',
    Death: 'Involuntary death of employee',
    Conversion: 'Conversion',
    EndContract: 'End Contract',
  },
  occupationTypes,
  DaysUntilAvailabilityPreferencesStale: 29,
  DaysUntilAvailabilityPreferencesTriggerAutoIdle: 60,
  DaysUntilAvailabilityPreferencesTriggerAutoAlumReservoir: 120,
  CounselWorkPreferences: ['Open To', 'Prefers', 'None', null] as Array<
    string | null
  >,
  RateFields: [
    'displayLowHourlyRate',
    'displayHighHourlyRate',
    'displayWeeklyRate',
  ],
  Badges: {
    Contractor: 'Contractor',
  },
} as const;
Object.freeze(CandidatesConst);

const defaultBaseFields = [
  'barredLocations',
  'calculatedDisplayName',
  'candidateHeader',
  'hasRate',
  'id',
  'industries',
  'isAvailableToConsult',
  'isExternalView',
  'isSpotlightedLawyer',
  'searchableByClient',
  'isViewOnly',
  'lawDegreeSchools',
  'lawFirms',
  'occupationType',
  'practiceArea',
  'profileImageKey',
  'profileImageName',
  'publicCandidateSummary',
  'publicRefId',
  'restricted',
  'yearsOfExperience',
];

export const CandidateWhiteLists = {
  CLIENT_USER: {
    BASE_FIELDS_WHITELIST: [
      ...defaultBaseFields,
      'calculatedFirstName',
      'calculatedLastName',
      'addressCity',
      'addressCountry',
      'addressState',
      'badges',
      'calendar',
      'certifications',
      'degrees',
      'experiences',
      'languages',
      // special case, needs both due to SPV limits
      'weeklyAvailability',
      'weeklyAvailabilityLabel',
    ],
    RELATED_FIELDS_WHITELIST: {
      areaOfStudy: ['id', 'name', 'category', 'createdAt', 'updatedAt'],
      barredLocations: ['id', 'name'],
      practiceArea: ['id', 'name', 'parent', 'parentId'],
      industries: ['industryValue', 'yearsOfExperience'],
      degrees: [
        'degree',
        'institution',
        'yearAwarded',
        'areaOfStudyId',
        'areaOfStudy',
      ],
      experiences: [
        'client',
        'clientDomain',
        'description',
        'startDate',
        'endDate',
        'locationName',
        'locationAddressComponents',
        'locationPoint',
        'externalOpportunityName',
        'isAxiom',
        'isConfidential',
        'formattedStartDate',
        'formattedEndDate',
        'tags',
        'id',
        'name',
      ],
      languages: [
        'name',
        'languageCEFR',
        'languageProficiency',
        'languageSkill',
      ],
      certifications: ['award', 'institution', 'year'],
      lawFirms: ['client'],
      lawDegreeSchools: ['degree', 'institution', 'yearAwarded'],
    },
  },
  UNAUTHENTICATED_USER: {
    BASE_FIELDS_WHITELIST: [
      ...defaultBaseFields,
      'occupationType',
      'weeklyAvailabilityLabel',
      'occupationType',
      'addressCountry',
      'addressState',
      'badges',
      'certifications',
      'degrees',
      'languages',
    ],
    RELATED_FIELDS_WHITELIST: {
      barredLocations: ['id', 'name'],
      practiceArea: ['id', 'name'],
      industries: ['industryValue', 'yearsOfExperience'],
      lawFirms: ['client'],
      lawDegreeSchools: ['institution'],
      certifications: ['award', 'institution', 'year'],
      degrees: ['degree', 'institution', 'yearAwarded'],
      languages: [
        'name',
        'languageCEFR',
        'languageProficiency',
        'languageSkill',
      ],
    },
  },
  NON_LP_USER: {
    BASE_FIELDS_WHITELIST: [
      ...defaultBaseFields,
      'addressCity',
      'addressCountry',
      'addressState',
      'badges',
      'displayHighHourlyRate',
      'displayLowHourlyRate',
      'displayWeeklyRate',
      'occupationType',
      'weeklyAvailability',
      'certifications',
      'degrees',
      'languages',
    ],
    RELATED_FIELDS_WHITELIST: {
      barredLocations: ['id', 'name'],
      practiceArea: ['id', 'name'],
      industries: ['industryValue', 'yearsOfExperience'],
      lawFirms: ['client'],
      lawDegreeSchools: ['institution'],
      certifications: ['award', 'institution', 'year'],
      degrees: ['degree', 'institution', 'yearAwarded'],
      languages: [
        'name',
        'languageCEFR',
        'languageProficiency',
        'languageSkill',
      ],
    },
  },
} as const;

export const PublicRefIdStartNumber = 125000 as const;

// PR-1050 lawyers in certain countries can override the workday max
export const LawyerDesiredMaxHourOverride = 80 as const;
export const LawyerDesiredMaxHoursOverrideEligibleCountries = [
  CountryCodesAbbreviations.US,
  CountryCodesAbbreviations.CA,
  CountryCodesAbbreviations.UK,
  CountryCodesAbbreviations.HK,
  CountryCodesAbbreviations.SG,
];
Object.freeze(LawyerDesiredMaxHoursOverrideEligibleCountries);

export const CandidateStatusSortOrder = [
  CandidateStatuses.Engaged,
  CandidateStatuses.Selected,
  CandidateStatuses.Interviewing,
  CandidateStatuses.Submitted,
  CandidateStatuses.WarmedYes,
  CandidateStatuses.Warmed,
  CandidateStatuses.RepBioShare,
  OppCandMlRecStatuses.AiSuggested,
  CandidateStatuses.Interested,
  CandidateStatuses.ShortList,
  CandidateStatuses.Suggested,
  CandidateStatuses.Rejected,
  CandidateStatuses.Removed,
  CandidateStatuses.Cooled,
  CandidateStatuses.TalentOptOut,
  CandidateStatuses.InterestedRejected,
  OppCandMlRecStatuses.AiRemoved,
];
Object.freeze(CandidateStatusSortOrder);

export const SpotlightedTalentCountryCodes = [CountryCodesAbbreviations.US];
