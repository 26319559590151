import moment from 'moment';
import { CalendarEventType, CalendarComponentEvent } from '@axiom/validation';

const translateFromStartTimeEndTimeProps = (
  events: CalendarEventType[]
): CalendarComponentEvent[] => {
  return events.reduce(
    (
      data: CalendarComponentEvent[],
      event: CalendarEventType
    ): CalendarComponentEvent[] => {
      const { endTime, startTime, ...rest } = event;
      const item: CalendarComponentEvent = {
        ...rest,
      };

      if (endTime) {
        item.end = moment(endTime).toDate();
      }
      if (startTime) {
        item.start = moment(startTime).toDate();
      }

      data.push(item);
      return data;
    },
    []
  );
};

const translateToStartTimeEndTimeProps = (
  events: CalendarComponentEvent[]
): CalendarEventType[] => {
  return events.reduce(
    (
      data: CalendarEventType[],
      event: CalendarComponentEvent
    ): CalendarEventType[] => {
      const { end, start, error, ...rest } = event;
      const item: CalendarEventType = {
        ...rest,
      };

      if (end) {
        item.endTime = moment(end).toDate();
      }
      if (start) {
        item.startTime = moment(start).toDate();
      }
      if (error) {
        item.error = error;
      }

      data.push(item);
      return data;
    },
    []
  );
};

const sortEvents = (events: CalendarComponentEvent[]) => {
  if (events.length >= 2) {
    events.sort((a, b) => {
      const aStart = moment(a.start);
      const bStart = moment(b.start);
      const aEnd = moment(a.end);
      const bEnd = moment(b.end);

      if (aStart.isBefore(bStart) || aEnd.isBefore(bEnd)) return -1;

      return 1;
    });
  }
  return events;
};

export const CalendarEventsUtil = {
  translateFromStartTimeEndTimeProps,
  translateToStartTimeEndTimeProps,
  sortEvents,
};
