import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import withDragAndDrop, {
  EventInteractionArgs,
} from 'react-big-calendar/lib/addons/dragAndDrop';
import {
  Calendar as BigCalendar,
  Culture,
  DateLocalizer,
  momentLocalizer,
  Views,
} from 'react-big-calendar';
import { CalendarEventType, CalendarComponentEvent } from '@axiom/validation';
import { CalendarPermissionsUtil, useBreakpoint } from '@axiom/ui';

import { useOnDependencyUpdate } from '../../../hooks/useOnDependencyUpdate';
import { calendarPermissionsType } from '../../../utils/calendar-permissions-util';

import { CalendarEventsUtil } from './calendar-events-util';
import { CalendarTimeSlot } from './CalendarTimeSlot';
import { EventStates, useMergeEvents } from './useMergeEvents';
import { useAccessors } from './useAccessors';
import { CalendarEvent } from './CalendarEvent';
import { CalendarToolbar } from './CalendarToolbar';
import { CalendarDayColumn } from './CalendarDayColumn';
import { CalendarDeleteEventModal } from './CalendarDeleteEventModal';

// REQUIRED: DON'T REMOVE ======= START
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
// REQUIRED: DON'T REMOVE ======= END

const allZones = moment.tz.names();
const DnDCalendar = withDragAndDrop(BigCalendar);

export const TIME_FORMATS = {
  MONTH_DAY: 'MMMM D',
  MONTH_ABBR_DAY: 'ddd M/DD',
  HOUR_MINS: 'hh:mm a',
};

type UpdateEventArgType = Omit<
  EventInteractionArgs<CalendarEventType>,
  'start' | 'end'
> & {
  start: CalendarComponentEvent['start'];
  end: CalendarComponentEvent['end'];
};

type TimeConfig = {
  increments?: number; // smallest selectable time
  slots?: number; // slots per section*
};

export type OnChangeArgType = {
  newEvents: CalendarComponentEvent[];
  modifiedEvents: CalendarComponentEvent[];
  deleteEventIds: CalendarComponentEvent['id'][];
};

/* section = increments * slots
 * 1 * 60  = 1hr sections with 1hr selectable events
 * 2 * 30 =  1hr sections with 30min selectable events
 * */

export type CalendarProps = {
  permissions: calendarPermissionsType;
  events: CalendarEventType[];
  startMode?: typeof Views.WEEK | typeof Views.WORK_WEEK | typeof Views.DAY;
  weekMode?: TimeConfig;
  dayMode?: TimeConfig;
  backgroundEvents?: CalendarEventType[];
  scrollToTime?: Date;
  timezone?: typeof allZones[number];
  height?: string;
  onChange?: (prop: OnChangeArgType) => void;
  displayDate?: Date;
};

export const Calendar = ({
  displayDate = new Date(),
  events = [],
  backgroundEvents = [],
  startMode = Views.WEEK,
  weekMode,
  dayMode,
  scrollToTime,
  timezone,
  height = '600px',
  permissions,
  onChange = () => {},
}: CalendarProps) => {
  const invalidMomentDays = [0, 5, 6];
  const { isMobile } = useBreakpoint();
  const [perms] = useState(
    isMobile ? CalendarPermissionsUtil().getBasePermissions() : permissions
  );
  const [eventData, setEventData] = useState({
    events: CalendarEventsUtil.translateFromStartTimeEndTimeProps(
      events.filter(event => {
        return moment(event.endTime).isAfter(moment(), 'day');
      })
    ),
    deleteIds: [],
  });
  const [selectedEvent, setSelectedEvent] = useState<CalendarComponentEvent>(
    {}
  );
  const [timeZoneChanged, setTimeZoneChanged] = useState(false);
  const [allBackgroundEvents] = useState(
    CalendarEventsUtil.translateFromStartTimeEndTimeProps(
      backgroundEvents
        .filter(event => {
          return moment(event.endTime).isAfter(moment(), 'day');
        })
        .map((bgE: CalendarEventType) => ({
          ...bgE,
          name: bgE.name ? `BACKGROUND ${bgE.name}` : 'BACKGROUND_EVENT',
        }))
    )
  );
  const timesConfig = useMemo(() => weekMode || dayMode, [weekMode, dayMode]);
  const mergeEvents = useMergeEvents();
  const { handleDraggableAccessor, handleResizableAccessor } =
    useAccessors(perms);

  const { appLocalizer, defaultDate, toTime } = useMemo(() => {
    moment.tz.setDefault(timezone);

    return {
      defaultDate: invalidMomentDays.includes(moment(displayDate).weekday())
        ? moment().startOf('isoWeek').add(1, 'week').toDate()
        : moment(displayDate).toDate(),
      appLocalizer: momentLocalizer(moment),
      toTime: scrollToTime || moment().subtract(1, 'hour').toDate(),
    };
  }, [timezone]);

  // https://github.com/jquense/react-big-calendar/blob/master/stories/demos/exampleCode/rendering.js
  const { components, formats } = useMemo(
    () => ({
      formats: {
        dayRangeHeaderFormat: (
          { start, end }: { start: Date; end: Date },
          culture: Culture,
          localizer: DateLocalizer
        ) => {
          const sameMonth = moment(start).month() === moment(end).month();
          const startFormat = TIME_FORMATS.MONTH_DAY;
          const endFormat = sameMonth ? 'D' : startFormat;

          return `${localizer.format(start, TIME_FORMATS.MONTH_DAY, culture)}${
            sameMonth ? '-' : ' - '
          }${localizer.format(end, endFormat, culture)}`;
        },
        dayFormat: (date: Date, culture: string, localizer: DateLocalizer) =>
          localizer.format(date, TIME_FORMATS.MONTH_ABBR_DAY, culture),
        eventTimeRangeFormat: (
          { start, end }: { start: Date; end: Date },
          culture: Culture,
          localizer: DateLocalizer
        ) =>
          `${localizer.format(
            start,
            TIME_FORMATS.HOUR_MINS,
            culture
          )} - ${localizer.format(end, TIME_FORMATS.HOUR_MINS, culture)}`,
      },
      components: {
        toolbar: CalendarToolbar,
        event: CalendarEvent,
        timeSlotWrapper: CalendarTimeSlot,
        dayColumnWrapper: CalendarDayColumn,
      },
    }),
    []
  );

  useEffect(() => {
    return () => {
      moment.tz.setDefault(); // reset to browser TZ on unmount
    };
  }, []);

  useOnDependencyUpdate(() => {
    /**
     * This is not run on the 'on mount' render cycle
     * since it is only needed if "eventData" changes
     * aka a new or modified event from the user
     * */
    const cleanEvents = eventData.events.reduce(
      (data, event: CalendarComponentEvent) => {
        const tmpEvent: CalendarComponentEvent = {
          ...event,
          id: event.id.startsWith('UNSAVED_') ? null : event.id,
          state: null,
        };

        const cleanEvent = Object.keys(tmpEvent).reduce(
          (crnt, key: keyof typeof tmpEvent) => {
            const value = tmpEvent[key];
            if (value !== null) Object.assign(crnt, { [key]: value });

            return crnt;
          },
          {} as CalendarComponentEvent
        );

        if (event.state === EventStates.NEW) {
          data.newEvents.push(cleanEvent);
        } else if (event.state === EventStates.MODIFIED || timeZoneChanged) {
          data.modifiedEvents.push(cleanEvent);
        }

        return data;
      },
      {
        newEvents: [],
        modifiedEvents: [],
        deleteEventIds: [],
      } as OnChangeArgType
    );

    onChange({
      newEvents: CalendarEventsUtil.translateToStartTimeEndTimeProps(
        cleanEvents.newEvents
      ),
      modifiedEvents: CalendarEventsUtil.translateToStartTimeEndTimeProps(
        cleanEvents.modifiedEvents
      ),
      deleteEventIds: eventData.deleteIds.filter(
        id => !id.startsWith('UNSAVED_')
      ),
    });
  }, [eventData, timeZoneChanged]);

  useOnDependencyUpdate(() => {
    setTimeZoneChanged(true);
  }, [timezone]);

  const eventPropGetter = useCallback((event: CalendarComponentEvent) => {
    const data = {
      className: 'nonDraggable',
    };

    if (event.busy) {
      data.className = `staticEvent ${
        perms.staticEvent.canMove ? 'isDraggable' : 'nonDraggable'
      }`;
    } else if (!event.busy) {
      data.className = perms.eventBoundary.canMove
        ? 'isDraggable'
        : 'nonDraggable';
    }

    if (event.error) {
      data.className += ' error';
    }

    return data;
  }, []);

  const selectSlot = (props: {
    start: Date;
    end: Date;
    action: 'select' | 'click' | 'doubleClick';
  }): null => {
    const canCreateActiveEvent =
      perms.staticEvent.canCreate || perms.staticEvent.canDragToCreate;
    const newEvent: CalendarComponentEvent = {
      id: `UNSAVED_${Math.round(Math.random() * 1e17) + 1e17}`,
      title: canCreateActiveEvent ? 'Interview' : '',
      start: props.start,
      end: props.end,
      busy: canCreateActiveEvent,
      error: null,
      state: EventStates.NEW,
    };

    if (perms.staticEvent.canCreate && !perms.staticEvent.canDragToCreate) {
      if (props.action === 'select') {
        // if trying to dragCreate
        return null;
      }

      // For setting an staticEvent ================= START
      // move to "eventData.events" if not wanting it in the background
      const eventBoundaryEvent = allBackgroundEvents.find(abe => {
        return (
          moment(newEvent.start).isBetween(
            abe.start,
            abe.end,
            undefined,
            '[]'
          ) &&
          moment(newEvent.end).isBetween(abe.start, abe.end, undefined, '[]')
        );
      });

      if (
        eventData.events.find(event => event.busy) &&
        !moment(newEvent.end).isSameOrBefore(moment(), 'day') &&
        eventBoundaryEvent
      ) {
        // staticEvent already in place for talent
        setEventData(prevState =>
          mergeEvents({ ...prevState, events: [newEvent] })
        );
      }

      if (!eventBoundaryEvent) {
        // staticEvent is not within the free time
        return null;
      }
      // For setting an staticEvent ================= END
    } else if (
      !perms.eventBoundary.canCreate ||
      moment(newEvent.end).isSameOrBefore(moment(), 'day')
    ) {
      return null;
    }
    setEventData(prevState =>
      mergeEvents({ ...prevState, events: [...prevState.events, newEvent] })
    );

    return null;
  };

  const handleSelectSlot = useCallback(selectSlot, [eventData]);

  const onSelecting = (event: CalendarComponentEvent) => {
    return (
      moment(event.end).isAfter(moment(), 'day') &&
      (perms.eventBoundary.canDragToCreate || perms.staticEvent.canDragToCreate)
    );
  };

  const handleOnSelecting = useCallback(onSelecting, []);

  const updateEvent = useCallback(
    ({ event, start, end }: UpdateEventArgType) => {
      setEventData(prevState => {
        const toBeMerged = prevState.events.reduce((allEvents, calEvent) => {
          const data: CalendarComponentEvent = {
            ...calEvent,
            error: null,
          };

          if (data.id === event.id) {
            // modifying an existing event
            data.start = start;
            data.end = end;
            data.state =
              data.state === EventStates.NEW
                ? data.state
                : EventStates.MODIFIED;
          }

          if (
            (data.busy &&
              data.state === EventStates.NEW &&
              // is staticEvent in valid time slot
              !allBackgroundEvents.find(abe => {
                return (
                  moment(data.start).isBetween(
                    abe.start,
                    abe.end,
                    undefined,
                    '[]'
                  ) &&
                  moment(data.end).isBetween(
                    abe.start,
                    abe.end,
                    undefined,
                    '[]'
                  )
                );
              })) ||
            (!data.busy &&
              // is eventBoundary in valid time slot
              (moment(data.end).isSameOrBefore(moment(), 'day') ||
                moment(data.start).isSameOrBefore(moment(), 'day')))
          ) {
            data.error = 'Unavailable';
          }

          allEvents.push(data);

          return allEvents;
        }, [] as CalendarEventType[]);

        return mergeEvents({ ...prevState, events: toBeMerged });
      });
    },
    [eventData, allBackgroundEvents]
  );

  const handleOnEventDrop = updateEvent;

  const handleOnEventResize = updateEvent;

  const onSelectHandler = (ev: CalendarComponentEvent) => {
    if (ev?.isBackgroundEvent) return;
    setSelectedEvent(ev);
  };

  const onDeleteHandler = async () => {
    setEventData(prevState => ({
      ...prevState,
      events: prevState.events.filter(ev => ev.id !== selectedEvent.id),
      deleteIds: [...prevState.deleteIds, selectedEvent.id],
    }));
    setSelectedEvent({});
  };

  // Calendar will error if defaultView={startMode} is not one of the "views"
  return (
    <>
      <div data-test="CALENDAR">
        <DnDCalendar
          onSelectEvent={onSelectHandler}
          defaultDate={defaultDate}
          defaultView={isMobile ? 'day' : startMode}
          events={eventData.events}
          localizer={appLocalizer}
          scrollToTime={toTime}
          views={[Views.WEEK, Views.WORK_WEEK, Views.DAY]}
          backgroundEvents={allBackgroundEvents}
          eventPropGetter={eventPropGetter}
          step={timesConfig.increments}
          timeslots={timesConfig.slots}
          style={{ height }}
          components={components}
          formats={formats}
          resizable={perms.eventBoundary.canResize}
          selectable={
            perms.staticEvent.canCreate ||
            perms.eventBoundary.canCreate ||
            perms.staticEvent.canDragToCreate ||
            perms.eventBoundary.canDragToCreate
              ? 'ignoreEvents'
              : false
          }
          draggableAccessor={handleDraggableAccessor}
          resizableAccessor={handleResizableAccessor}
          onSelectSlot={handleSelectSlot}
          onSelecting={handleOnSelecting}
          onEventDrop={handleOnEventDrop}
          onEventResize={handleOnEventResize}
          showMultiDayTimes
          dayPropGetter={useCallback(date => {
            return {
              className: moment(date).isBefore(moment().endOf('day'))
                ? 'rbc-inactive-block'
                : '',
            };
          }, [])}
        />
      </div>
      {Object.keys(selectedEvent).length > 0 && (
        <CalendarDeleteEventModal
          permissions={perms}
          onDelete={onDeleteHandler}
          onClose={() => setSelectedEvent({})}
          deleteEvent={selectedEvent}
        />
      )}
    </>
  );
};
