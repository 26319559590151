import { CandidateOpportunitiesConst } from '@axiom/const';

const { CandidateStatuses, OppCandMlRecStatuses } = CandidateOpportunitiesConst;

export const TALENT_POOL_ALLOWED_TRANSITIONS = {
  [CandidateStatuses.InterestedRejected]: [CandidateStatuses.ShortList],
  [CandidateStatuses.Interested]: [
    CandidateStatuses.ShortList,
    CandidateStatuses.Suggested,
    CandidateStatuses.InterestedRejected,
  ],
  [CandidateStatuses.Suggested]: [
    CandidateStatuses.ShortList,
    CandidateStatuses.RepBioShare,
    CandidateStatuses.Removed,
  ],
  [CandidateStatuses.ShortList]: [
    CandidateStatuses.RepBioShare,
    CandidateStatuses.Removed,
  ],
  [CandidateStatuses.RepBioShare]: [
    CandidateStatuses.ShortList,
    CandidateStatuses.Removed,
  ],
  [CandidateStatuses.Removed]: [
    CandidateStatuses.ShortList,
    CandidateStatuses.RepBioShare,
  ],
};

export const DEFAULT_ALLOWED_TRANSITIONS = {
  [CandidateStatuses.Completed]: [],
  [CandidateStatuses.Cooled]: [
    CandidateStatuses.Warmed,
    CandidateStatuses.WarmedYes,
  ],
  [CandidateStatuses.Engaged]: [],
  [CandidateStatuses.InterestedRejected]: [CandidateStatuses.ShortList],
  [CandidateStatuses.Interviewing]: [
    CandidateStatuses.Selected,
    CandidateStatuses.TalentOptOut,
    CandidateStatuses.Rejected,
    CandidateStatuses.Cooled,
  ],
  [CandidateStatuses.Rejected]: [
    CandidateStatuses.Warmed,
    CandidateStatuses.WarmedYes,
    CandidateStatuses.Cooled,
  ],
  [CandidateStatuses.Removed]: [
    CandidateStatuses.ShortList,
    CandidateStatuses.RepBioShare,
    CandidateStatuses.Warmed,
    CandidateStatuses.WarmedYes,
  ],
  [CandidateStatuses.RepBioShare]: [
    CandidateStatuses.Warmed,
    CandidateStatuses.WarmedYes,
    CandidateStatuses.TalentOptOut,
    CandidateStatuses.Removed,
  ],
  [CandidateStatuses.Selected]: [
    CandidateStatuses.Interviewing,
    CandidateStatuses.TalentOptOut,
    CandidateStatuses.Rejected,
    CandidateStatuses.Cooled,
  ],
  [CandidateStatuses.ShortList]: [
    CandidateStatuses.RepBioShare,
    CandidateStatuses.Warmed,
    CandidateStatuses.WarmedYes,
    CandidateStatuses.TalentOptOut,
    CandidateStatuses.Removed,
  ],
  [CandidateStatuses.Submitted]: [
    CandidateStatuses.Interviewing,
    CandidateStatuses.Selected,
    CandidateStatuses.TalentOptOut,
    CandidateStatuses.Rejected,
    CandidateStatuses.Cooled,
  ],
  [CandidateStatuses.Interested]: [
    CandidateStatuses.ShortList,
    CandidateStatuses.Suggested,
    CandidateStatuses.RepBioShare,
    CandidateStatuses.InterestedRejected,
  ],
  [CandidateStatuses.Suggested]: [
    CandidateStatuses.ShortList,
    CandidateStatuses.RepBioShare,
    CandidateStatuses.Warmed,
    CandidateStatuses.WarmedYes,
    CandidateStatuses.TalentOptOut,
    CandidateStatuses.Removed,
  ],
  [CandidateStatuses.TalentOptOut]: [
    CandidateStatuses.Warmed,
    CandidateStatuses.WarmedYes,
  ],
  [CandidateStatuses.Warmed]: [
    CandidateStatuses.WarmedYes,
    CandidateStatuses.WarmedUnsure,
    CandidateStatuses.TalentOptOut,
    CandidateStatuses.Rejected,
    CandidateStatuses.Cooled,
  ],
  [CandidateStatuses.WarmedUnsure]: [
    CandidateStatuses.WarmedYes,
    CandidateStatuses.TalentOptOut,
    CandidateStatuses.Rejected,
    CandidateStatuses.Cooled,
  ],
  [CandidateStatuses.WarmedYes]: [
    CandidateStatuses.TalentOptOut,
    CandidateStatuses.Rejected,
    CandidateStatuses.Cooled,
  ],
};

export const FULFILLED_POSITION_ALLOWED_TRANSITIONS = {
  [CandidateStatuses.Rejected]: [CandidateStatuses.Cooled],
};

export const OPPORTUNITY_CLOSED_ALLOWED_TRANSITIONS = {
  [CandidateStatuses.Rejected]: [CandidateStatuses.Cooled],
  [CandidateStatuses.Removed]: [CandidateStatuses.Cooled],
};

export const LPUSER_ALLOWED_TRANSITIONS = {
  [CandidateStatuses.Warmed]: [
    CandidateStatuses.WarmedYes,
    CandidateStatuses.WarmedUnsure,
    CandidateStatuses.TalentOptOut,
  ],
};

export const ML_RECOMMENDATION_ALLOWED_TRANSITIONS = {
  [OppCandMlRecStatuses.AiRemoved]: [],
  [OppCandMlRecStatuses.AiSuggested]: [
    CandidateStatuses.ShortList,
    CandidateStatuses.Suggested,
    OppCandMlRecStatuses.AiRemoved,
  ],
};
