import React from 'react';
import { Formik } from 'formik';
import isObjectLike from 'lodash/isObjectLike';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';

import { FormSchemaUtil } from '../../../utils/form-schema-util';

import { FormConstruction } from './FormConstruction';
import { FormProps } from './FormTypes';

type GenericFormObjectType = { [key: string | number]: unknown };

export const isDifferent = (value: unknown, compareAgainst: unknown) => {
  if (isObjectLike(value) && isObjectLike(compareAgainst)) {
    try {
      return !isEqual(value, compareAgainst);
    } catch (e) {
      return true;
    }
  }
  return value !== compareAgainst;
};

export const filterOutUnchangedData = (
  initialData?: unknown,
  finalData?: unknown
) => {
  if (!initialData || !isObjectLike(initialData)) {
    return finalData;
  }
  if (!isObjectLike(finalData)) {
    return finalData;
  }

  const allKeys = uniq(Object.keys(finalData));
  return allKeys.reduce((acc: GenericFormObjectType, propName) => {
    if (
      (!has(initialData, propName) && has(finalData, propName)) ||
      (has(initialData, propName) &&
        has(finalData, propName) &&
        isDifferent(get(finalData, propName), get(initialData, propName)))
    ) {
      acc[propName] = get(finalData, propName);
    }

    return acc;
  }, {});
};

export const Form = <FormValues,>({
  children,
  initialValues,
  name = 'Form',
  onSubmit = () => {},
  schema,
  submitOnChange,
}: FormProps<FormValues>) => {
  if (!initialValues) {
    if (FormSchemaUtil.isObjectSchema(schema)) {
      initialValues = {} as FormValues;
    } else if (FormSchemaUtil.isArraySchema(schema)) {
      initialValues = [] as FormValues;
    }
  }

  return (
    <Formik
      validate={values => {
        const { errors } = FormSchemaUtil.validate(schema, values);
        if (process.env.NODE_ENV === 'development' && errors) {
          // eslint-disable-next-line no-console
          console.log('(local only) Validation Info: ', { errors, values });
        }
        return errors || {};
      }}
      initialValues={initialValues}
      onSubmit={(formData, actions) => {
        const { values } = FormSchemaUtil.validate(schema, formData);
        const changedData = filterOutUnchangedData(
          initialValues,
          values
        ) as FormValues;
        return onSubmit(changedData, actions);
      }}
      enableReinitialize // allow form selection to update if initialValues change
    >
      {({
        handleReset,
        handleSubmit,
        isSubmitting,
        submitCount,
        setTouched,
        setFieldTouched,
        setValues,
        values,
      }) => {
        return (
          <FormConstruction
            handleReset={handleReset}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            name={name}
            schema={schema}
            setTouched={setTouched}
            setFieldTouched={setFieldTouched}
            setValues={setValues}
            submitCount={submitCount}
            submitOnChange={submitOnChange}
            values={values}
          >
            {children}
          </FormConstruction>
        );
      }}
    </Formik>
  );
};
