import React from 'react';
import { number } from 'prop-types';

import { LoadingGradientWrapper } from './LoadingGradientStyles';

const LoadingGradient = ({ lines }) => {
  const linesArr = [...new Array(lines)];
  return (
    <LoadingGradientWrapper>
      {linesArr.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={index} className="block-placeholder" />
      ))}
    </LoadingGradientWrapper>
  );
};

LoadingGradient.defaultProps = {
  lines: 3,
};

LoadingGradient.propTypes = {
  lines: number,
};

export default LoadingGradient;
