import React from 'react';
import { node, string } from 'prop-types';

import { Gutter } from '../../layout/Gutter/Gutter';

import { CMContainer, CMWrapper, CMText } from './CardMessageStyles';

export const CardMessage = ({ name, children, footer }) => (
  <CMContainer>
    <CMWrapper data-test={name}>
      {typeof children === 'string'
        ? children
            .split(/(?:\r\n|\r|\n)/g)
            .map((p, index) =>
              p === '' ? (
                <br key={index.toString()} />
              ) : (
                <CMText key={p}>{p}</CMText>
              )
            )
        : children}
    </CMWrapper>
    {footer && (
      <Gutter bottom="1.5rem" horizontal="1.5rem">
        {footer}
      </Gutter>
    )}
  </CMContainer>
);

CardMessage.propTypes = {
  name: string,
  children: node,
  footer: node,
};

CardMessage.defaultProps = {
  name: null,
  children: null,
  footer: null,
};
