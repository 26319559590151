import { stringify } from 'qs';
import requestLib from 'superagent';

import {
  APIError,
  BadRequestError,
  AuthorizationError,
  AuthenticationError,
} from '../api/errors';

async function request(name, endpoint, method, body, headers = {}, opts = {}) {
  try {
    const formattedEndpoint = endpoint.startsWith('https')
      ? endpoint
      : `/api${endpoint}`;
    const requestObj = requestLib[method.toLowerCase()](formattedEndpoint).set({
      ...(body instanceof FormData
        ? {}
        : { 'Content-Type': 'application/json' }),
      ...headers,
    });

    const response = await requestObj.send(body);

    return opts?.bufferedRequest ? response.text : response.body;
  } catch (e) {
    if (!e || !e.response) {
      throw new Error(e);
    }

    const { response } = e;

    const { body: errBody } = response;
    const obj = {
      name,
      endpoint,
      method,
      body: errBody || {},
      headers,
      response,
    };

    switch (response.status) {
      case 400:
        throw new BadRequestError(obj);
      case 403:
        throw new AuthenticationError(obj);
      case 401:
        throw new AuthorizationError(obj);
      default:
        throw new APIError(obj);
    }
  }
}

export const get = (name, endpoint, body, headers, opts) =>
  request(name, `${endpoint}/?${stringify(body)}`, 'GET', null, headers, opts);

export const post = (name, endpoint, body) =>
  request(name, endpoint, 'POST', JSON.stringify(body));

export const put = (name, endpoint, body) =>
  request(name, endpoint, 'PUT', JSON.stringify(body));

export const patch = (name, endpoint, body) =>
  request(name, endpoint, 'PATCH', JSON.stringify(body));

export const httpDelete = (name, endpoint) => request(name, endpoint, 'DELETE');

export const multiPost = (name, endpoint, body) => {
  const formData = new FormData();
  Object.keys(body).forEach(key => {
    if (Array.isArray(body[key])) {
      body[key].forEach(val => formData.append(`${key}[]`, val));
    } else {
      formData.append(key, body[key]);
    }
  });
  return request(name, endpoint, 'POST', formData);
};

export const multiPatch = (name, endpoint, body) => {
  const formData = new FormData();
  Object.keys(body).forEach(key => {
    if (Array.isArray(body[key])) {
      body[key].forEach(val => formData.append(`${key}[]`, val));
    } else {
      formData.append(key, body[key]);
    }
  });
  return request(name, endpoint, 'PATCH', formData);
};

// NOTE: Right now this just curries API names, but in the future could be
// extended to allow APIs to pass other custom data/callbacks
export default class ApiHelper {
  constructor(name) {
    this.name = name;
  }

  GET(...args) {
    return get(this.name, ...args);
  }

  POST(...args) {
    return post(this.name, ...args);
  }

  PUT(...args) {
    return put(this.name, ...args);
  }

  PATCH(...args) {
    return patch(this.name, ...args);
  }

  DELETE(...args) {
    return httpDelete(this.name, ...args);
  }
}
