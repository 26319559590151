import {
  SubmissionCandidate,
  Submission,
  CandidateOpportunity,
} from '../../../validation';
import { CurrencyUtil } from '../../../utils/src/currency';
import { CandidateOpportunitiesConst } from '../../../const/src/candidate-opportunities';
import { CurrencySymbolTypes } from '../../../const/src/currency';

const { hourly, daily, weekly } =
  CandidateOpportunitiesConst.DisplayBillingRate;

export const CandidateRateUtil = {
  formatCandidateRate(
    candidate: SubmissionCandidate | CandidateOpportunity,
    currency: CurrencySymbolTypes,
    submission?: Submission
  ) {
    const cBill = candidate?.position?.billingHoursPerDay;

    const billingHoursPerDay =
      cBill ||
      submission?.positions?.find(position =>
        position?.candidateOpportunities?.find(
          c => c.candidate.id === candidate.id
        )
      )?.billingHoursPerDay;
    const { displayBillingRate, proposedHourlyRate } = candidate;
    const { DisplayBillingRate } = CandidateOpportunitiesConst;

    let price = proposedHourlyRate;
    let increment = '';
    if (
      !price ||
      price < 0 ||
      Number.isNaN(price) ||
      displayBillingRate === DisplayBillingRate.none
    ) {
      return '--';
    }

    switch (displayBillingRate) {
      case hourly:
        increment = 'hour';
        break;
      case daily:
        price *= billingHoursPerDay;
        increment = 'day';
        break;
      case weekly:
        price *= billingHoursPerDay * 5;
        increment = 'week';
        break;
      default:
        return null;
    }

    if (
      candidate?.proposedHourlyRate &&
      candidate?.displayBillingRate !== DisplayBillingRate.none &&
      (candidate.displayBillingRate === DisplayBillingRate.hourly ||
        billingHoursPerDay >= 0)
    ) {
      return `${currency} ${CurrencyUtil.getFormattedCurrency(
        price
      )}/${increment}`;
    }

    return '--';
  },
};
