import React from 'react';

export default () => (
  <div>
    <p>Last Modified: July 19, 2021</p>
    <p>
      <strong>1. Acceptance of the Terms of Use</strong>
    </p>
    <p>
      <em>
        This section states that you are entering into contract terms with Axiom
        that govern your use of the Axiom Platform located at
        <a href="https://apps.axiomlaw.com" target="_blank" rel="noreferrer">
          https://apps.axiomlaw.com
        </a>
        (the "<strong>Platform</strong>").
      </em>
    </p>
    <p>
      These terms and conditions, together with any documents they expressly
      incorporate by reference (collectively, "<strong>Terms of Use</strong>"),
      govern your access to and use of the Axiom Platform located at
      <a href="https://apps.axiomlaw.com">https://apps.axiomlaw.com</a> (the "
      <strong>Platform</strong>"), including any content, functionality,
      services or products available to you on or through the Platform. By using
      the Platform, you agree to be bound by these Terms of Use. If you do not
      agree to these Terms of Use you must not use the Platform. "
      <strong>You</strong>" means you individually and the Axiom Client that you
      represent (and, as applicable, your Users). If you are entering into the
      Agreement for an Axiom Client, you represent that you have the authority
      to bind that entity.
    </p>
    <p>
      Please read these Terms of Use carefully before you start to use the
      Platform.
      <strong>
        By using the Platform or by clicking to accept or agree to the Terms of
        Use when this option is made available to you, you accept and agree to
        be bound and abide by these Terms of Use and by our{' '}
      </strong>
      <a href="https://www.axiomlaw.com/privacy-notice">
        <strong>Privacy Notice</strong>
      </a>
      <strong> and our </strong>
      <a
        href="https://www.axiomlaw.com/axiom-cookie-notice"
        target="_blank"
        rel="noreferrer"
      >
        <strong>Cookies Notice</strong>
      </a>
      <strong>, both of which are incorporated herein by reference.</strong> If
      you do not agree to these Terms of Use or the
      <a
        href="https://www.axiomlaw.com/privacy-notice"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Notice
      </a>
      or
      <a
        href="https://www.axiomlaw.com/axiom-cookie-notice"
        target="_blank"
        rel="noreferrer"
      >
        Cookie Notice
      </a>
      , you must not access or use the Platform.
    </p>
    <p>
      <strong>2. Changes to the Terms of Use</strong>
    </p>
    <p>
      <em>
        This section explains that Axiom may update these terms from time to
        time, without notice to you, and the updated terms will apply to you.
      </em>
    </p>
    <p>
      We may revise and update these Terms of Use from time to time in our sole
      discretion. All changes are effective immediately when we post them.
    </p>
    <p>
      Your continued use of the Platform following the posting of revised Terms
      of Use means that you accept and agree to the changes. You are expected to
      check this page from time to time so you are aware of any changes, as they
      are binding on you.
    </p>
    <p>
      <strong>3. Platform Users</strong>
    </p>
    <p>
      <em>
        This section outlines that these terms apply to all types of users.
      </em>
    </p>
    <p>
      These Terms of Use apply to you whether you are a user of the Platform
      that is:
    </p>
    <ul>
      <li>
        an employee, contractor or other authorised user (“
        <strong>Client User</strong>”) of an Axiom client (“
        <strong>Axiom</strong> <strong>Client</strong>”);
      </li>
      <li>
        legal talent engaged by and providing services or prospective services
        through Axiom, whether as an employee, contractor or otherwise (“
        <strong>Axiom Legal Talent</strong>”); or
      </li>
      <li>
        any other employee, contractor or authorised user of Axiom (“
        <strong>Axiom HQ</strong>”).
      </li>
    </ul>
    <br />
    <br />
    <p>
      <strong>
        4. Purpose of the Platform
        <br />
      </strong>
    </p>
    <p>
      <em>
        This section describes the purpose of the Axiom Platform for each type
        of user.
      </em>
    </p>
    <p>
      Axiom is the global leader in expert, on-demand legal talent and its
      Platform is a trusted resource that has been created for the benefit of
      all of its users. In particular, the Axiom Platform benefits the
      following:
    </p>
    <ul>
      <li>
        Axiom Clients – Your time is precious and your partnership with Axiom to
        find the right Legal Talent for your team, with the experience and
        expertise that you need is important to us. The Platform allows us to
        streamline and accelerate our process with you and our Legal Talent to
        support you much more efficiently. It gives you a single source from
        which you can engage with Axiom and our Legal Talent, manage ongoing
        engagements and review Legal Talent on the Platform (including being
        able to re-engage with the Legal Talent you have previously worked with
        by having their profile saved within your account as Legal Talent on
        your “bench”). We want to collaborate and provide additional
        transparency to you in a way that makes it an easier and more positive
        experience for you.
        <br />
        <br />
      </li>
      <li>
        Axiom Legal Talent – We value your legal experiences and respect that
        you have joined Axiom to own your career choices. The Platform gives you
        greater control, transparency and visibility into potential
        opportunities that are aligned with your preferences. You will have more
        control by being able to locate all key information to help you with
        your development and engagements within a single source. For example,
        you will have your own profile which you can update as and when
        necessary (including on your work preferences, such as hours and
        location). Clients who are interested in your profile will be able to
        reach out to you directly, to discuss potential engagements. You will be
        able to locate your main Axiom contacts (such as your Legal Talent
        Partner) and useful information and advice which can further help you to
        grow. We want to collaborate with you in a way that truly showcases your
        experience allowing our clients the opportunity to partner with you.
        <br />
        <br />
      </li>
      <li>
        Axiom HQ – You play a critical part in connecting our clients and Legal
        Talent, and we want to help you to do that in a way that is more
        efficient and fulfilling for you. The Platform allows you to have a
        single source in which to communicate with our clients and Legal Talent
        to support them in their journeys with us. As the Platform has been
        integrated with other key systems within our business, we believe that
        this will allow you to easily track, monitor and execute opportunities
        and therefore help our key stakeholders. We want to collaborate with you
        in a way that allows you to do what you enjoy: interacting with our
        clients and Legal Talent, without having to use multiple forms of
        communication and systems separately in order to do so.
      </li>
    </ul>
    <p>
      <strong>
        <br />
        5. Accessing the Platform and Account Security
        <br />
      </strong>
    </p>
    <p>
      <em> This section explains that:</em>
    </p>
    <ul>
      <li>
        <em>
          Axiom may modify or withdraw the Platform, in its sole discretion and
          without notice to you. Axiom is not liable for unavailability of the
          Platform and may restrict access to it.
        </em>
      </li>
      <li>
        <em>
          you are responsible for ensuring that you can access the Platform and
          comply with these Terms of Use.
        </em>
      </li>
      <li>
        <em>
          your login information is confidential and you are not allowed to
          share it. You will notify Axiom of any unauthorised access to your
          account.
        </em>
      </li>
    </ul>
    <p>
      <br />
      We reserve the right to terminate or modify the Platform, and any
      services, data or material we provide on the Platform, in our sole
      discretion without notice. We will not be liable if for any reason all or
      any part of the Platform is unavailable at any time or for any period.
      From time to time, we may restrict access to some or all of the Platform.
    </p>
    <p>You are responsible for both:</p>
    <ul>
      <li>
        making all arrangements necessary for you to have access to the
        Platform; and
      </li>
      <li>
        ensuring that all persons who access the Platform through your internet
        connection are aware of these Terms of Use and comply with them.
      </li>
    </ul>
    <p>
      <br />
      To access the Platform or some of the resources it offers, you may be
      asked to provide certain registration details or other information. It is
      a condition of your use of the Platform that all the information you
      provide on the Platform is correct, current, and complete. You agree that
      all information you provide to register with and use the Platform or
      otherwise, including, but not limited to, through the use of any
      interactive features on the Platform, is also governed by our
      <a href="https://www.axiomlaw.com/privacy-notice">Privacy Notice</a>, and
      you agree to all actions we take with respect to your information
      consistent with our
      <a href="https://www.axiomlaw.com/privacy-notice">Privacy Notice</a>.
    </p>
    <p>
      If you create, or are provided with, a username (email), password, or any
      other piece of information as part of the Platform’s security procedures,
      you must treat such information as confidential, and you must not disclose
      it to any other person or entity. You also acknowledge that your account
      is personal to you and agree not to provide any other person with access
      to the Platform or portions of it using your username (email), password,
      or other security information. Your ability to invite a colleague to use
      the Platform is only permitted through the “Download” and “Share”
      functionality available on the Platform. You will be held responsible for
      any action taken via your account and will be held accountable for the
      validity of anyone that you invite to join the Platform.
    </p>
    <p>
      You agree to notify us immediately of any unauthorized access to or use of
      your username (email) or password or any other breach of security. You
      also agree to ensure that you exit/logoff from the Platform at the end of
      each session. You should use particular caution when accessing the
      Platform from a public or shared computer so that others are not able to
      view or record your password or other personal information.
    </p>
    <p>
      We have the right to disable any username (email), password, or other
      identifier, whether chosen by you or provided by us, at any time in our
      sole discretion for any or no reason, including if, in our opinion, you
      have violated any provision of these Terms of Use.
    </p>
    <p>
      <strong>
        6. User Contributions
        <br />
      </strong>
    </p>
    <p>
      <em>
        With respect to your profile and other content that you submit on the
        Platform, this section explains that:
      </em>
    </p>
    <ul>
      <li>
        <em>
          you grant Axiom a license to use this content as required for the
          Platform, and you promise that you have the right to do so.
        </em>
      </li>
      <li>
        <em>
          you agree that you are responsible for this content, including its
          legality, reliability, accuracy, and appropriateness.
        </em>
      </li>
    </ul>
    <p>
      <br />
      The Platform contains profiles, message boards, forums and other
      interactive features (collectively, "<strong>Interactive Services</strong>
      ") that allow users to post, submit, publish, display, or transmit to
      other users or other persons (hereinafter, "<strong>post</strong>")
      content or materials (collectively, "<strong>User Contributions</strong>")
      on or through the Platform.
    </p>
    <p>
      More specifically, the Platform allows Axiom Legal Talent to create
      profiles that are accessible by Client Users, which profiles are included
      within the definition of User Contributions. All User Contributions must
      comply with the Content Standards set out below in these Terms of Use.
    </p>
    <p>
      By providing any User Contribution on the Platform, you grant us and our
      affiliates a worldwide, non-exclusive, royalty-free, transferable,
      sub-licensable license to use, copy, modify, process, reproduce, adapt,
      distribute and publish such User Contribution as we deem reasonably
      necessary in connection with our business (such as in order to promote
      Axiom’s products and services) and to fulfil our contractual relationship
      with you. User Contributions may not have been verified or approved by us
      and we have no obligation to do so. The views expressed by other users on
      the Platform do not represent our views or values. If you wish to complain
      about content uploaded by other users, please contact us at
      <a href="mailto:answers@axiomlaw.com">answers@axiomlaw.com</a>.
    </p>
    <p>You represent and warrant that:</p>
    <ul>
      <li>
        you own or have all necessary licenses and rights in and to the User
        Contributions and have the right to grant the license granted above to
        us and our affiliates; and
      </li>
      <li>
        all of your User Contributions do and will comply with these Terms of
        Use.
      </li>
    </ul>
    <p>
      <br />
      As an Axiom Client or Client User, you understand and acknowledge that you
      are solely responsible for any User Contributions you submit or
      contribute, and you, not Axiom, have full responsibility for such content,
      including its legality, reliability, accuracy, and appropriateness. We are
      not responsible or liable to any third party for the content or accuracy
      of any User Contributions posted Axiom Clients or Client Users.
    </p>
    <p>
      <strong>
        7. Content Standards
        <br />
      </strong>
    </p>
    <p>
      <em>
        This section lists the rules that your profile and other content that
        you submit on the Platform.
      </em>
    </p>
    <p>
      These content standards apply to any and all User Contributions and use of
      Interactive Services. User Contributions must, in their entirety, comply
      with all applicable federal, state, local, and international laws, rules
      and regulations. User Contributions including the user’s name and contact
      details must be accurate and kept up-to-date.
    </p>
    <p>Without limiting the foregoing, User Contributions must not:</p>
    <ul>
      <li>
        contain any material that is defamatory, obscene, indecent, abusive,
        offensive, harassing, violent, hateful, inflammatory, or otherwise
        objectionable;
      </li>
      <li>
        promote sexually explicit or pornographic material, violence, or
        discrimination based on race, sex, religion, nationality, disability,
        sexual orientation, or age;
      </li>
      <li>
        infringe any patent, trademark, trade secret, copyright, or other
        intellectual property or other rights of any other person;
      </li>
      <li>
        violate the legal rights (including the rights of publicity and privacy)
        of others or contain any material that could give rise to any civil or
        criminal liability under applicable laws or regulations or that
        otherwise may be in conflict with these Terms of Use and our
        <a href="https://www.axiomlaw.com/privacy-notice">Privacy Notice</a>;
      </li>
      <li>be likely to deceive any person;</li>
      <li>
        promote any illegal activity, or advocate, promote, or assist any
        unlawful act;
      </li>
      <li>
        impersonate any person, or misrepresent your identity or affiliation
        with any person or organization;
      </li>
      <li>
        involve commercial activities or sales, such as contests, sales
        promotions, barter, or advertising; and/or
      </li>
      <li>
        give the impression that they emanate from or are endorsed by us or any
        other person or entity, if this is not the case.
      </li>
    </ul>
    <p>
      <strong>
        <br />
        8. Prohibited Uses
        <br />
      </strong>
    </p>
    <p>
      <em>
        This section describes how you may use the Platform and what you are not
        allowed to do.
      </em>
    </p>
    <p>
      You may use the Platform only for lawful purposes, such as in order to
      fulfil your contract with us and for your legitimate business activities
      with us and in accordance with these Terms of Use. You agree not to use
      the Platform:
    </p>
    <ul>
      <li>
        in any way that violates any applicable federal, state, local, or
        international law, rule or regulation (including, without limitation,
        any laws regarding the export of data or software to and from the United
        States, European Union or other countries such as those within Asia);
      </li>
      <li>
        to send, knowingly receive, upload, download, use, or re-use any
        material that does not comply with the Content Standards described below
        in these Terms of Use;
      </li>
      <li>
        to transmit, or procure the sending of, any advertising or promotional
        material without our prior written consent, including any "junk mail,"
        "chain letter," "spam," or any other similar solicitation;
      </li>
      <li>
        to impersonate or attempt to impersonate Axiom, Axiom HQ, another user,
        or any other person or entity (including, without limitation, by using
        email addresses or screen names associated with any of the foregoing);
        and/or
      </li>
      <li>
        to engage in any other conduct that restricts or inhibits anyone's use
        or enjoyment of the Platform, or which, as determined by us, may harm
        Axiom or users of the Platform, or expose them to liability.
      </li>
    </ul>
    <p>
      <br />
      You agree not to:
    </p>
    <ul>
      <li>
        use the Platform in any manner that could disable, overburden, damage,
        or impair the Platform or interfere with any other party's use of the
        Platform, including their ability to engage in real time activities
        through the Platform;
      </li>
      <li>
        use any robot, spider, or other automatic device, process, or means to
        access the Platform for any purpose, including monitoring or copying any
        of the material on the Platform;
      </li>
      <li>
        use any manual process to monitor or copy any of the material on the
        Platform, or for any other purpose not expressly authorized in these
        Terms of Use, without our prior written consent;
      </li>
      <li>
        use any device, software, or routine that interferes with the proper
        working of the Platform;
      </li>
      <li>
        introduce any viruses, Trojan horses, worms, logic bombs, or other
        material that is malicious or technologically harmful;
      </li>
      <li>
        attempt to gain unauthorized access to, interfere with, damage, or
        disrupt any parts of the Platform or any server, computer, or database
        connected to the Platform;
      </li>
      <li>
        attack the Platform via a denial-of-service attack or a distributed
        denial-of-service attack; and/or
      </li>
      <li>
        otherwise attempt to interfere with the proper working of the Platform.
      </li>
    </ul>
    <p>
      <br />
      Additionally, you agree that:
    </p>
    <ul>
      <li>
        if you are a Client User, you may have access to non-public confidential
        information about Axiom Legal Talent, such as full names and resumes,
        and accordingly you agree as follows:
      </li>
      <ul>
        <li>
          you may share this information with your Axiom Client colleagues only,
          without exception, and this includes not sharing this information with
          any of your Axiom Client partner or vendor companies or other third
          parties; and
        </li>
        <li>
          this sharing of information is only permitted through the “Download”
          and/or “Share” functionality available on the Platform;
        </li>
      </ul>
      <li>
        you will not reproduce or publicly display any profile information about
        Axiom Legal Talent or Axiom HQ;
      </li>
      <li>
        you will not conduct automated queries on the services provided by the
        Platform; and/or
      </li>
      <li>
        you will not access or use the Platform or any of the products or
        services provided by the Platform to develop competitive products or
        services.
      </li>
    </ul>
    <p>
      <br />
      And, finally, you agree that:
    </p>
    <ul>
      <li>
        in the event that a Client User identifies an Axiom Legal Talent within
        the Platform and solicits the Axiom Legal Talent outside of the
        Platform, the Axiom Client will be subject to the conversion fee
        included within its contract with Axiom; and
      </li>
      <li>
        in the event that no contract is in place between the Client User and
        Axiom, the conversion fee is 25% of the (i) annual salary plus target
        bonus if the Axiom Legal Talent is hired as an employee or (ii)
        annualized contracted fee if the Axiom Legal Talent engaged as a
        contractor.
      </li>
    </ul>
    <p>
      <strong>
        <br />
        9. Intellectual Property Rights
        <br />
      </strong>
    </p>
    <p>
      <em>This section describes that:</em>
    </p>
    <ul>
      <li>
        <em>
          Axiom and its affiliates or licensors own all of the intellectual
          property rights in the Platform.
        </em>
      </li>
      <li>
        <em>
          you may only use the Platform for your business purposes with Axiom.
        </em>
      </li>
      <li>
        <em>
          you may not copy, download or otherwise use any of the materials on
          the Platform, other than for the limited reasons that are described.
        </em>
      </li>
    </ul>
    <p>
      <br />
      The Platform and its entire contents, features, and functionality
      (including but not limited to all information, software, text, displays,
      images, video, and audio, and the design, selection, and arrangement
      thereof) are owned by Axiom, its licensors, or other providers of such
      material and are protected by United States, European Union and other
      international and local copyright, trademark, patent, trade secret, and
      other intellectual property or proprietary rights laws.
    </p>
    <p>
      These Terms of Use permit you to use the Platform for your personal,
      legitimate business interests with Axiom only. You must not reproduce,
      distribute, copy, modify, create derivative works of, publicly display,
      publicly perform, republish, download, store, or transmit any of the
      material on the Platform, except as follows:
    </p>
    <ul>
      <li>
        your computer may temporarily store copies of such materials in RAM
        incidental to your accessing and viewing those materials;
      </li>
      <li>
        you may store files that are automatically cached by your web browser
        for display enhancement purposes; and
      </li>
      <li>
        you may not extract data or present data elsewhere within the Platform
        outside of the ways that are made directly available to you through the
        Platform. For example, you may not take screenshots of the Platform.
        Sharing of information is only permitted through the “Download” and/or
        “Share” functionality that is made available on the Platform.
      </li>
    </ul>
    <p>
      <br />
      You must not:
    </p>
    <ul>
      <li>modify copies of any materials from the Platform; or</li>
      <li>
        delete or alter any copyright, trademark, or other proprietary rights
        notices from copies of materials from the Platform.
      </li>
    </ul>
    <p>
      <br />
      If you print, copy, modify, download, or otherwise use or provide any
      other person with access to any part of the Platform in breach of the
      Terms of Use, your right to use the Platform will stop immediately and you
      must, at our option, return or destroy any copies of the materials you
      have made. No right, title, or interest in or to the Platform or any
      content on the Platform is transferred to you, and all rights not
      expressly granted are reserved by Axiom. Any use of the Platform not
      expressly permitted by these Terms of Use is prohibited, constitutes a
      breach of these Terms of Use and may violate copyright, trademark, and
      other laws.
    </p>
    <p>
      <strong>
        10. Trademarks
        <br />
      </strong>
    </p>
    <p>
      <em>
        The section explains that the Axiom name, logo and other Axiom
        trademarks on the Platform belong to Axiom and its affiliates or
        licensors.
      </em>
    </p>
    <p>
      The Axiom name, trademarks, logo, and all related names, logos, product
      and service names, designs, and slogans are trademarks of Axiom or its
      affiliates or licensors. You must not use such marks without the prior
      written permission of Axiom. All other names, logos, product and service
      names, designs, and slogans on this Platform are the trademarks of their
      respective owners.
    </p>
    <p>
      <strong>11. Monitoring and Enforcement; Termination</strong>
    </p>
    <p>
      <em>
        This section outlines Axiom’s rights with respect to the content that
        you post on the Platform, including your profile. This section also
        describes that Axiom has the right to terminate your access and in
        certain circumstances disclose your identity or take legal action. You
        provide Axiom with a waiver against any claims made due to these
        actions.
      </em>
    </p>
    <p>We have the right to:</p>
    <ul>
      <li>
        remove or refuse to post any User Contributions for any or no reason in
        our sole discretion;
      </li>
      <li>
        take any action with respect to any User Contribution that we deem
        necessary or appropriate in our sole discretion, including if we believe
        that such User Contribution violates the Terms of Use, including the
        Content Standards, infringes any intellectual property right or other
        right of any person or entity, threatens the personal safety of users of
        the Platform or the public, or could create a risk or liability for
        Axiom;
      </li>
      <li>
        disclose your identity and other information about you to any third
        party who claims that material posted by you violates their rights,
        including their intellectual property rights or their right to privacy;
      </li>
      <li>
        take legal action, including without limitation, referral to law
        enforcement, for any illegal or unauthorized use of the Platform or
        violation of these Terms of Use; and
      </li>
      <li>
        terminate or suspend your access to all or part of the Platform for any
        or no reason, including without limitation, any violation of these Terms
        of Use.
      </li>
    </ul>
    <p>
      <br />
      Without limiting the foregoing, we have the right to cooperate fully with
      any law enforcement authorities or court order requesting or directing us
      to disclose the identity or other information of anyone posting any
      materials on or through the Platform. YOU WAIVE AND HOLD HARMLESS AXIOM
      FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY AXIOM DURING, OR TAKEN
      AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER AXIOM OR LAW ENFORCEMENT OR
      OTHER GOVERNMENT AUTHORITIES.
    </p>
    <p>
      <strong>12. Copyright Infringement</strong>
    </p>
    <p>
      <em>
        This section explains that you will notify us if you believe that any
        other user content on the Platform violates your own copyright.
      </em>
    </p>
    <p>
      If you believe that any User Contributions violate your copyright, please
      send us a notice of copyright infringement to
      <a href="mailto:legal@axiomlaw.com">legal@axiomlaw.com</a>. It is the
      policy of Axiom to terminate the user accounts of repeat infringers.
    </p>
    <p>
      <strong>13. Changes to the Platform</strong>
    </p>
    <p>
      <em>
        This section states that Axiom does not promise that the content on the
        Platform will always be complete or current.
      </em>
    </p>
    <p>
      We aim to keep the content on this Platform up-to-date and accurate, but
      at times the content may not be complete or current.
    </p>
    <p>
      <strong>14. Information About You and Your Visits to the Platform</strong>
    </p>
    <p>
      <em>
        This section states that all information collected on the Platform is
        subject to the Axiom Privacy Notice.
      </em>
    </p>
    <p>
      All information we collect on this Platform is subject to our
      <a href="https://www.axiomlaw.com/privacy-notice">Privacy Notice</a>. By
      using the Platform, you consent to all actions taken by us with respect to
      your information in compliance with the
      <a href="https://www.axiomlaw.com/privacy-notice">Privacy Notice</a>.
    </p>
    <p>
      <strong>15. Links from the Platform</strong>
    </p>
    <p>
      <em>
        This section states that links to third party websites and resources on
        the Platform are to be used at your own risk and Axiom accepts no
        responsibility for your use of them.
      </em>
    </p>
    <p>
      If the Platform contains links to other websites and resources provided by
      third parties, these links are provided for your convenience only. We have
      no control over the contents of those websites or resources, and accept no
      responsibility for them or for any loss or damage that may arise from your
      use of them. If you decide to access any of the third-party websites
      linked to from the Platform, you do so entirely at your own risk and
      subject to the terms and conditions of use for such websites.
    </p>
    <p>
      <strong>16. Geographic Restrictions</strong>
    </p>
    <p>
      <em>
        This section explains that you are responsible for accessing the
        Platform in compliance with local laws.
      </em>
    </p>
    <p>
      The owner of the Platform is incorporated and registered in Delaware and
      has its registered office in New York State. We provide this Platform for
      use by persons located worldwide, including the United States, European
      Union and elsewhere. Access to the Platform may not be legal by certain
      persons or in certain countries and should you access the Platform from
      those countries, you do so on your own initiative and are responsible for
      compliance with local laws.
    </p>
    <p>
      <strong>17. Disclaimer of Warranties</strong>
    </p>
    <p>
      <em>
        This section is Axiom’s disclaimer of legal liability for your use of
        the Platform.
      </em>
    </p>
    <p>
      You understand that we cannot and do not guarantee or warrant that files
      available for downloading from the Internet or the Platform will be free
      of viruses or other destructive code. You are responsible for implementing
      sufficient procedures and checkpoints to satisfy your particular
      requirements for anti-virus protection and accuracy of data input and
      output. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR
      ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
      VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
      COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL
      DUE TO YOUR USE OF THE PLATFORM OR ANY SERVICES OR ITEMS OBTAINED THROUGH
      THE PLATFORM OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON
      ANY WEBSITE LINKED TO IT.
    </p>
    <p>
      YOUR USE OF THE PLATFORM, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
      THROUGH THE PLATFORM IS AT YOUR OWN RISK. THE PLATFORM, ITS CONTENT, AND
      ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM ARE PROVIDED ON AN “AS
      IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
      EXPRESS OR IMPLIED. NEITHER AXIOM NOR ANY PERSON ASSOCIATED WITH AXIOM
      MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
      SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE PLATFORM.
      WITHOUT LIMITING THE FOREGOING, NEITHER AXIOM NOR ANYONE ASSOCIATED WITH
      AXIOM REPRESENTS OR WARRANTS THAT THE PLATFORM, ITS CONTENT, OR ANY
      SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM WILL BE ACCURATE,
      RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
      THAT OUR PLATFORM OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
      VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE PLATFORM OR ANY SERVICES
      OR ITEMS OBTAINED THROUGH THE PLATFORM WILL OTHERWISE MEET YOUR NEEDS OR
      EXPECTATIONS.
    </p>
    <p>
      TO THE FULLEST EXTENT PROVIDED BY LAW, AXIOM HEREBY DISCLAIMS ALL
      WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
      OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
      NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
    </p>
    <p>
      THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
      LIMITED UNDER APPLICABLE LAW.
    </p>
    <p>
      <strong>18. Limitation on Liability</strong>
    </p>
    <p>
      <em>
        This section sets out the limits of Axiom’s legal liability for your use
        of the Platform.
      </em>
    </p>
    <p>
      TO THE FULLEST EXTENT PROVIDED BY LAW, THE MAXIMUM LIABILITY OF AXIOM, AND
      ITS LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR
      DIRECTORS FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF
      OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE PLATFORM, ANY
      WEBSITES LINKED TO IT, ANY CONTENT ON THE PLATFORM OR SUCH OTHER WEBSITES,
      INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
      PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
      SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF
      BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
      DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
      CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE, WILL NOT EXCEED $100.
    </p>
    <p>
      The limitation of liability set out above does not apply to liability
      resulting from our gross negligence or wilful misconduct or to death or
      bodily injury caused by products or services you purchase through the
      Platform.
    </p>
    <p>
      THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
      LIMITED UNDER APPLICABLE LAW.
      <strong>
        <br />
      </strong>
    </p>
    <p>
      <strong>19. Indemnification</strong>
    </p>
    <p>
      <em>
        This section sets out the indemnity that you agree to with respect to
        your use of the Platform.
      </em>
    </p>
    <p>
      You agree to defend, indemnify, and hold harmless Axiom, its licensors,
      and service providers, and its and their respective officers, directors,
      employees, contractors, agents, licensors, suppliers, successors, and
      assigns from and against any claims, liabilities, damages, judgments,
      awards, losses, costs, expenses, or fees (including reasonable attorneys’
      fees) arising out of or relating to your violation of these Terms of Use
      or your use of the Platform, including, but not limited to, your User
      Contributions, any use of the Platform’s content, services, and products
      other than as expressly authorized in these Terms of Use, or your use of
      any information obtained from the Platform.
    </p>
    <p>
      <strong>20. Governing Law and Jurisdiction</strong>
    </p>
    <p>
      <em>
        This section describes the governing law that applies to this contract
        and the courts that have jurisdiction.
      </em>
    </p>
    <p>
      All matters relating to the Platform and these Terms of Use, and any
      dispute or claim arising therefrom or related thereto (in each case,
      including non-contractual disputes or claims), shall be governed by and
      construed in accordance with the internal laws of the State of New York
      without regard to its principles relating to conflicts of law.
    </p>
    <p>
      Any legal suit, action, or proceeding arising out of, or related to, these
      Terms of Use or the Platform shall be instituted exclusively in the
      federal courts of the United States or the courts of the state of New York
      and the United States District Court for the Southern District of New York
      located in New York County, although we retain the right to bring any
      suit, action, or proceeding against you for breach of these Terms of Use
      in your country of residence or any other relevant country. You waive any
      and all objections to the exercise of jurisdiction over you by such courts
      and to venue in such courts.
    </p>
    <p>
      <strong>21. Limitation on Time to File Claims</strong>
    </p>
    <p>
      <em>
        This section sets out the timeframe within which you must bring any
        claim against Axiom.
      </em>
    </p>
    <p>
      ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
      THESE TERMS OF USE OR THE PLATFORM MUST BE COMMENCED WITHIN ONE (1) YEAR
      AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR
      CLAIM IS PERMANENTLY BARRED.
    </p>
    <p>
      <strong>22. Waiver and Severability</strong>
    </p>
    <p>
      <em>
        This section describes what happens if Axiom waives a term of this
        contract, or if any term of this contract is held to be invalid, illegal
        or unenforceable.
      </em>
    </p>
    <p>
      No waiver by Axiom of any term or condition set out in these Terms of Use
      shall be deemed a further or continuing waiver of such term or condition
      or a waiver of any other term or condition, and any failure of Axiom to
      assert a right or provision under these Terms of Use shall not constitute
      a waiver of such right or provision.
    </p>
    <p>
      If any provision of these Terms of Use is held by a court or other
      tribunal of competent jurisdiction to be invalid, illegal, or
      unenforceable for any reason, such provision shall be eliminated or
      limited to the minimum extent such that the remaining provisions of the
      Terms of Use will continue in full force and effect.
    </p>
    <p>
      <strong>23. Survival</strong>
    </p>
    <p>
      <em>
        This section lists those terms that continue to apply beyond the
        termination of this contract.
      </em>
    </p>
    <p>
      On termination of these Terms of Use, the following sections along with
      this section shall continue in force: Sections 9, 10, 18, 20 – 26.
    </p>
    <p>
      <strong>24. Entire Agreement</strong>
    </p>
    <p>
      <em>
        This section states that this contract (and any documents incorporated
        and referenced) constitutes the entire agreement between you and Axiom.
      </em>
    </p>
    <p>
      The Terms of Use which incorporate our
      <a href="https://www.axiomlaw.com/privacy-notice">Privacy Notice</a> and
      <a href="https://www.axiomlaw.com/axiom-cookie-notice">Cookies Notice</a>
      and any other contractual documentation which we have entered into with
      you constitute the sole and entire agreement between you and Axiom Global
      Inc. regarding the Platform and supersedes all prior and contemporaneous
      understandings, agreements, representations, and warranties, both written
      and oral, regarding the Platform.
    </p>
    <p>
      <strong>25. Conflicts with Other Terms and Conditions</strong>
    </p>
    <p>
      <em>
        This section describes what happens in the event of a conflict between
        this contract and any other contract that Axiom enters into with you.
      </em>
    </p>
    <p>
      All relationships and transactions facilitated through the Platform,
      including the engagement of Axiom Legal Talent by Axiom, or the engagement
      of Axiom Legal Talent by Axiom Clients, are governed by the contracts that
      you enter into with us with respect these services. Your use of the
      Platform is subject to any other contracts you have with us, and in the
      event of any conflict between these Terms of Use and any contract you have
      with us, the terms of such contract will prevail.
    </p>
    <p>
      <strong>
        26. Your Comments and Concerns
        <br />
      </strong>
    </p>
    <p>
      <em>
        This section explains where you can send your notices and comments.
      </em>
    </p>
    <p>
      The Platform is operated by Axiom Global Inc. whose registered office is
      at 3 World Trade Center at 175 Greenwich Street, 50th Floor, New York, NY
      10007.
    </p>
    <p>
      All feedback, comments, requests for technical support, and other
      communications relating to the Platform should be directed in the first
      instance to:{' '}
      <a href="mailto:answers@axiomlaw.com">answers@axiomlaw.com</a>.
    </p>
  </div>
);
