import qs from 'qs';
import { shape, string } from 'prop-types';
import { isSafari } from '@axiom/ui/src/utils/detect-browser';

import Cookie from '../utils/browser-cookies';
import { EnvUtil } from '../utils/env-util';
import GenericStore from '../classes/generic-store';
import { AjvUtil } from '../utils/ajv-util';
import { loginAs, requestMagicLink } from '../api/auth/auth';
import { ShowableError } from '../classes/showable-error';
import { WindowUtil } from '../utils/window-util';

// eslint-disable-next-line import/no-cycle
import { FormLoginStore } from './form-login-store';

const FormLoginValidation = AjvUtil.compileSchema({
  required: ['email', 'password'],
  properties: {
    email: {
      title: 'Email',
      type: 'string',
      format: 'email',
      isNotEmpty: true,
    },
    password: {
      title: 'Password',
      type: 'string',
      isNotEmpty: true,
    },
  },
});

class FormManualLoginStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      email: string,
      password: string,
    });
  }

  load(email) {
    return this.setState({
      email,
      password: null,
    });
  }

  validate(data) {
    return AjvUtil.formatValidationForFinalForm(FormLoginValidation, data);
  }

  sendTokenLoginEmail(email) {
    const { RelayState } = encodeURIComponent(window.location.search)
      ? qs.parse(WindowUtil.getFullRedirect(window.location), {
          ignoreQueryPrefix: true,
        })
      : { RelayState: '' };

    return this.clearState(requestMagicLink(email, RelayState));
  }

  cancel() {
    const { RelayState } = encodeURIComponent(window.location.search)
      ? qs.parse(WindowUtil.getFullRedirect(window.location), {
          ignoreQueryPrefix: true,
        })
      : { RelayState: '' };

    this.clearState();
    FormLoginStore.load();
    window.location.href = RelayState
      ? `/?RelayState=${encodeURIComponent(RelayState)}`
      : '/';
  }

  submit(formData) {
    return this.clearState(
      loginAs(formData)
        .then(jwt => {
          const isBrowserSafari = isSafari();
          Cookie('user', jwt, {
            domain: `${EnvUtil.cookieDomain}`,
            path: '/',
            SameSite: isBrowserSafari ? 'None' : 'Lax',
            secure: isBrowserSafari,
          });

          const { RelayState } = encodeURIComponent(window.location.search)
            ? qs.parse(WindowUtil.getFullRedirect(window.location), {
                ignoreQueryPrefix: true,
              })
            : { RelayState: '' };

          window.location.href = `/sso/?RelayState=${encodeURIComponent(
            RelayState
          )}`;
        })
        .catch(() => {
          throw new ShowableError(
            'An error occurred. Please try again or reach out to <a href="mailto:client.answers@axiomlaw.com">Axiom Answers for Clients</a> or <a href="mailto:answers@axiomlaw.com">Axiom Answers for Talent</a>.'
          );
        })
    );
  }
}

export const FormManualLoginStore = new FormManualLoginStoreClass();
