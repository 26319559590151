import React from 'react';

import { Icon } from '../Icon/Icon';
import { IconName } from '../Icon/IconTypes';

import { RawButton, RawButtonProps, RawButtonVariations } from './RawButton';

export const IconButtonVariations = {
  filled: RawButtonVariations.filled,
  minimal: RawButtonVariations.minimal,
} as const;

export type IconButtonVariation =
  typeof IconButtonVariations[keyof typeof IconButtonVariations];

export type IconButtonProps = {
  className?: RawButtonProps['className'];
  disabled?: RawButtonProps['disabled'];
  icon: IconName;
  id?: RawButtonProps['id'];
  inverse?: RawButtonProps['inverse'];
  name?: RawButtonProps['name'];
  onClick?: RawButtonProps['onClick'];
  pattern?: RawButtonProps['pattern'];
  tabIndex?: RawButtonProps['tabIndex'];
  title?: RawButtonProps['title'];
  to?: RawButtonProps['to'];
  toTab?: RawButtonProps['toTab'];
  type?: RawButtonProps['type'];
  variation?: IconButtonVariation;
};

export const IconButton = ({
  title,
  className,
  disabled,
  icon,
  id,
  inverse,
  name,
  onClick,
  pattern,
  tabIndex,
  to,
  toTab,
  type,
  variation,
}: IconButtonProps) => {
  const ButtonIcon = icon ? <Icon name={icon} /> : null;
  return (
    <RawButton
      className={`${className || ''} icon`.trim()}
      disabled={disabled}
      Icon={ButtonIcon}
      id={id}
      inverse={inverse}
      name={name}
      onClick={onClick}
      pattern={pattern}
      tabIndex={tabIndex}
      title={title}
      to={to}
      toTab={toTab}
      type={type}
      variation={variation}
    />
  );
};
