import React from 'react';
import { bool, node, string } from 'prop-types';
import { Label } from '@axiom/ui';

import { formatDataTestId } from '../../utils/dataTest';

import {
  SectionWrapper,
  DivLabelWrapper,
  DivLabelRightWrapper,
  DivFooterWrapper,
  DivFooterLeftWrapper,
  DivFooterRightWrapper,
} from './LabelWrapperStyles';

function LabelWrapper(props) {
  const { children, footerLeft, footerRight, label, labelRight, margin, name } =
    props;

  return (
    <SectionWrapper
      data-test={formatDataTestId(name || label || 'LABEL')}
      margin={margin}
    >
      <DivLabelWrapper>
        <Label>{label}</Label>
        <DivLabelRightWrapper>{labelRight}</DivLabelRightWrapper>
      </DivLabelWrapper>
      {children}
      <DivFooterWrapper>
        <DivFooterLeftWrapper>{footerLeft}</DivFooterLeftWrapper>
        <DivFooterRightWrapper>{footerRight}</DivFooterRightWrapper>
      </DivFooterWrapper>
    </SectionWrapper>
  );
}

LabelWrapper.defaultProps = {
  children: null,
  footerLeft: null,
  footerRight: null,
  labelRight: null,
  margin: true,
  name: null,
};

LabelWrapper.propTypes = {
  children: node,
  footerLeft: node,
  footerRight: node,
  label: node.isRequired,
  labelRight: node,
  margin: bool,
  name: string,
};

export default LabelWrapper;
