import qs from 'qs';
import { shape, string } from 'prop-types';
import { AuthConst } from '@axiom/const';

import GenericStore from '../classes/generic-store';
import { AjvUtil } from '../utils/ajv-util';
import { getAuthInfoForEmail } from '../api/auth/auth';
import { ShowableError } from '../classes/showable-error';
import { WindowUtil } from '../utils/window-util';

// eslint-disable-next-line import/no-cycle
import { FormManualLoginStore } from './form-manual-login-store';

export const FormEmailValidation = AjvUtil.compileSchema({
  required: ['email'],
  properties: {
    email: {
      title: 'Email',
      type: 'string',
      format: 'email',
      isNotEmpty: true,
    },
  },
});

class FormLoginStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      email: string,
    });
  }

  load() {
    return this.setState({
      email: null,
    });
  }

  validate(data) {
    return AjvUtil.formatValidationForFinalForm(FormEmailValidation, data);
  }

  submit(formData) {
    return this.clearState(
      getAuthInfoForEmail(formData.email, AuthConst.LoginFlows.Talent)
        .then(({ authRedirectUrl, flowType }) => {
          if (flowType) {
            FormManualLoginStore.load(formData.email);
          } else {
            const { RelayState } = encodeURIComponent(window.location.search)
              ? qs.parse(WindowUtil.getFullRedirect(window.location), {
                  ignoreQueryPrefix: true,
                })
              : { RelayState: '' };

            const { origin, pathname, search } = new URL(authRedirectUrl);
            window.location.href = `${origin}${pathname}?${qs.stringify({
              ...qs.parse(search, { ignoreQueryPrefix: true }),
              RelayState,
            })}`;
          }
        })
        .catch(() => {
          throw new ShowableError(
            'An error occurred. Please try again or reach out to<br /> <a href="mailto:client.answers@axiomlaw.com">Axiom Answers for Clients</a><br /> or <br /><a href="mailto:answers@axiomlaw.com">Axiom Answers for Talent</a>.'
          );
        })
    );
  }
}

export const FormLoginStore = new FormLoginStoreClass();
