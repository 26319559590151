import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApi } from '@axiom/ui';
import { AuthConst } from '@axiom/const';

import LoadingTreatment from '../LoadingTreatment/LoadingTreatment';
import { AuthApi } from '../../api/auth/auth';
import { WindowUtil } from '../../utils/window-util';

const { getUrlWithRelayState } = WindowUtil;

export const CheckEmailRedirect = () => {
  const location = useLocation();
  const { providedEmail } = useParams();
  const queryRaw = new URLSearchParams(location.search);
  const relayState = queryRaw.get('RelayState');
  const loginFlow = AuthConst.LoginFlows.Client;
  const queryRelayParam = relayState && { RelayState: relayState };
  const responses = useApi(
    providedEmail &&
      AuthApi.readAuthForEmail(providedEmail, {
        ...queryRelayParam,
        loginFlow,
      })
  );
  const { flowType, authRedirectUrl } = responses?.[0]?.data;
  const navigate = useNavigate();

  useEffect(() => {
    if (flowType === AuthConst.FlowTypes.Verify) {
      navigate(`../${providedEmail}/check-email`);
    } else if (flowType === AuthConst.FlowTypes.Prompt) {
      const URL = getUrlWithRelayState(`../${providedEmail}/password`, {
        query: queryRaw,
      });
      navigate(URL);
    } else if (authRedirectUrl) {
      const {
        origin,
        pathname,
        searchParams: authRedirectUrlSearchParams,
      } = new URL(authRedirectUrl);

      if (relayState) {
        authRedirectUrlSearchParams.append('RelayState', relayState);
      }

      window.location.assign(
        `${origin}${pathname}?${authRedirectUrlSearchParams.toString()}`
      );
    } else {
      navigate(getUrlWithRelayState('/client-login', { query: queryRaw }));
    }
  }, [providedEmail]);

  return <LoadingTreatment />;
};
