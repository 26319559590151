import {
  CandidateOpportunitiesConst,
  Opportunity,
  OpportunitiesConst,
  UserConst,
} from '@axiom/const';

import {
  DEFAULT_ALLOWED_TRANSITIONS,
  FULFILLED_POSITION_ALLOWED_TRANSITIONS,
  LPUSER_ALLOWED_TRANSITIONS,
  ML_RECOMMENDATION_ALLOWED_TRANSITIONS,
  OPPORTUNITY_CLOSED_ALLOWED_TRANSITIONS,
  TALENT_POOL_ALLOWED_TRANSITIONS,
} from './candidateStateConstants';

const { CandidateStatuses, OppCandMlRecStatuses } = CandidateOpportunitiesConst;
const { FulfillmentStatuses } = OpportunitiesConst;
const { Roles } = UserConst;

const checkFulfillmentComplete = (fulfillmentStatus, statusMap) =>
  FulfillmentStatuses.v(fulfillmentStatus) ===
  FulfillmentStatuses.FulfillmentComplete
    ? {
        ...statusMap,
        // NO CHANGES ALLOWED OUT OF SELECTED (esp Rejecting)
        [CandidateStatuses.Selected]: [],
      }
    : statusMap;

const getTransitionMap = ({
  currentCandidateStatus,
  opportunity,
  position,
  role,
}) => {
  const { fulfillmentStatus, stage: opportunityStage } = opportunity || {};

  if (role === Roles.LPUser) {
    return LPUSER_ALLOWED_TRANSITIONS;
  } else if (
    opportunityStage === Opportunity.CLOSED_WON ||
    opportunityStage === Opportunity.CLOSED_LOST
  ) {
    return OPPORTUNITY_CLOSED_ALLOWED_TRANSITIONS;
  } else if (!position) {
    return Object.values(OppCandMlRecStatuses).includes(currentCandidateStatus)
      ? ML_RECOMMENDATION_ALLOWED_TRANSITIONS
      : TALENT_POOL_ALLOWED_TRANSITIONS;
  }

  if (!position.candidates) {
    // position must have candidates property
    return {};
  }

  const isAnotherCandidateSelected =
    currentCandidateStatus !== CandidateStatuses.Selected &&
    position.candidates.some(
      ({ candidateStatus }) => candidateStatus === CandidateStatuses.Selected
    );

  const allowedTransitions = isAnotherCandidateSelected
    ? FULFILLED_POSITION_ALLOWED_TRANSITIONS
    : DEFAULT_ALLOWED_TRANSITIONS;

  return checkFulfillmentComplete(fulfillmentStatus, allowedTransitions);
};

export const getAllowedTransitions = ({
  currentCandidateStatus,
  opportunity,
  position,
  role,
}) =>
  getTransitionMap({
    currentCandidateStatus,
    opportunity,
    position,
    role,
  })[currentCandidateStatus] || [];

export const isValidTransition = ({
  currentCandidateStatus,
  opportunity,
  position,
  role,
  targetStatus,
}) =>
  getAllowedTransitions({
    currentCandidateStatus,
    position,
    opportunity,
    role,
  }).includes(targetStatus);
