import React from 'react';

import { CardLayout } from '../../general/CardLayout/CardLayout';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Header4 } from '../../content/Header4/Header4';

export const DSNoCandidate = () => (
  <Gutter bottom="80px" gutterType="margin" name="DS_NO_CANDIDATE">
    <CardLayout
      header={<></>}
      body={
        <>
          <Header4>
            We're working to find the best talent for you. We'll let you know
            when we add profiles here for you to review.
          </Header4>
        </>
      }
    />
  </Gutter>
);
