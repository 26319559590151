import React from 'react';
import { Submission, SubmissionCandidate } from '@axiom/validation';
import { CurrencyUtil } from '@axiom/utils';
import { CurrencyCodeKeyTypes, BarredLocationsConst } from '@axiom/const';
import { CandidateYearsOfExperienceModel } from '@axiom/ui-models';

import RateIcon from '../../../public/static/svg/rate-icon.svg';
import AdmissionsIcon from '../../../public/static/svg/admissions-icon.svg';
import EducationIcon from '../../../public/static/svg/education-icon.svg';
import ExperienceIcon from '../../../public/static/svg/experience-icon.svg';
import { CandidateRateUtil } from '../../../utils/candidate-rate-util';
import { Grid } from '../../layout/Grid/Grid';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { GridRow } from '../../layout/Grid/GridRow';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';

export type CandidateHighlightExperienceSummaryTypes = {
  candidate: SubmissionCandidate;
  submission?: Submission;
  condensed?: boolean;
};

export const CandidateHighlightExperienceSummary = ({
  candidate,
  submission = null,
  condensed = false,
}: CandidateHighlightExperienceSummaryTypes) => {
  const filteredLawDegreeSchools = [
    ...new Set(
      candidate?.lawDegreeSchools?.map(education => {
        const degree = education.degree ? `, ${education.degree}` : '';

        return `${education.institution}${degree}`;
      })
    ),
  ];

  const filteredBarredLocations = candidate?.barredLocations.filter(
    admission => admission.name !== BarredLocationsConst.Names.NotAdmitted
  );

  const calculatedRate =
    submission &&
    CandidateRateUtil.formatCandidateRate(
      candidate,
      CurrencyUtil.convertToSymbol(
        submission?.currency as CurrencyCodeKeyTypes
      ),
      submission
    ) !== '--' &&
    CandidateRateUtil.formatCandidateRate(
      candidate,
      CurrencyUtil.convertToSymbol(
        submission?.currency as CurrencyCodeKeyTypes
      ),
      submission
    );

  const lawSchoolsExist = candidate?.lawDegreeSchools?.length > 0;

  const yearsOfExperienceExist =
    candidate?.yearsOfExperience && candidate?.yearsOfExperience >= 0;

  const barredLocationsExist = filteredBarredLocations?.length > 0;

  const numberOfSummaryItems = [
    ...[
      calculatedRate,
      lawSchoolsExist,
      yearsOfExperienceExist,
      barredLocationsExist,
    ],
  ].filter(i => i).length;

  const calculateColumnWidth = () => {
    if (numberOfSummaryItems === 1) {
      return 12;
    } else if (condensed && numberOfSummaryItems === 4) {
      return 3;
    } else if (numberOfSummaryItems % 2 === 0) {
      return 6;
    }
    return 4;
  };

  const columnWidth = calculateColumnWidth();

  return (
    <Grid textAlign="justified" stackable name="highlights-experience-summary">
      <GridRow>
        {calculatedRate && (
          <GridColumn
            verticalAlign="top"
            largeScreenWidth={columnWidth}
            tabletWidth={columnWidth}
            mobileWidth={12}
            name="highlight-hourly-rate-section"
          >
            <Layout horizontalGutter="8px">
              <LayoutItem>
                <RateIcon width="44px" height="44px" />
              </LayoutItem>
              <LayoutItem>
                <ParagraphHeader name="RATE">Rate</ParagraphHeader>
                <Paragraph name="highlight-hourly-rate">
                  {calculatedRate}
                </Paragraph>
                <Gutter bottom="16px" />
              </LayoutItem>
            </Layout>
          </GridColumn>
        )}
        {lawSchoolsExist && (
          <GridColumn
            verticalAlign="top"
            largeScreenWidth={columnWidth}
            tabletWidth={columnWidth}
            mobileWidth={12}
            name="highlight-education-section"
          >
            <Layout horizontalGutter="8px">
              <LayoutItem>
                <EducationIcon width="44px" height="44px" />
              </LayoutItem>
              <LayoutItem>
                <ParagraphHeader>Education</ParagraphHeader>
                <Paragraph name="highlight-education">
                  {filteredLawDegreeSchools.map((school, index) => (
                    <div key={`admission-${index + 1}`}>{school}</div>
                  ))}
                </Paragraph>
                <Gutter bottom="16px" />
              </LayoutItem>
            </Layout>
          </GridColumn>
        )}
        {yearsOfExperienceExist && (
          <GridColumn
            verticalAlign="top"
            largeScreenWidth={columnWidth}
            tabletWidth={columnWidth}
            mobileWidth={12}
            name="highlight-experience-section"
          >
            <Layout horizontalGutter="8px">
              <LayoutItem>
                <ExperienceIcon width="44px" height="44px" />
              </LayoutItem>
              <LayoutItem>
                <ParagraphHeader>Experience</ParagraphHeader>
                <Paragraph name="highlight-experience">
                  {`${new CandidateYearsOfExperienceModel(
                    candidate.yearsOfExperience
                  ).getYearsOfExperienceRange()} years`}
                </Paragraph>
                <Gutter bottom="16px" />
              </LayoutItem>
            </Layout>
          </GridColumn>
        )}
        {barredLocationsExist && (
          <GridColumn
            verticalAlign="top"
            largeScreenWidth={columnWidth}
            tabletWidth={columnWidth}
            mobileWidth={12}
            name="highlight-admissions-section"
          >
            <Layout horizontalGutter="8px">
              <LayoutItem>
                <AdmissionsIcon width="44px" height="44px" />
              </LayoutItem>
              <LayoutItem>
                <ParagraphHeader>Admissions</ParagraphHeader>
                <Paragraph name="highlight-admissions">
                  {candidate?.barredLocations?.map(admission => (
                    <div key={`admission-${admission.name}`}>
                      {`${admission.name}`}
                    </div>
                  ))}
                </Paragraph>
                <Gutter bottom="16px" />
              </LayoutItem>
            </Layout>
          </GridColumn>
        )}
      </GridRow>
    </Grid>
  );
};
