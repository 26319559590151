import React from 'react';
import { Modal } from 'semantic-ui-react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export interface ModalSectionProps {
  children?: React.ReactNode;
  name?: string;
  className?: string;
  divider?: boolean;
}

export const ModalSection = ({
  children,
  name,
  className,
  divider = false,
}: ModalSectionProps) => {
  return (
    <Modal.Content
      scrolling
      data-test={name || 'MODAL_SECTION'}
      name={name}
      className={AttrsHelper.formatClassname(divider && 'divider', className)}
    >
      <Modal.Description>{children}</Modal.Description>
    </Modal.Content>
  );
};
