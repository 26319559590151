import React from 'react';

import { DefaultLayout } from '../layouts/DefaultLayout';
import TokenLogin from '../components/TokenLogin/TokenLogin';
import { Page } from '../components/Page/Page';

export const EmailTokenConfirmationPage = () => (
  <Page title="Axiom Law - Link Sent">
    <DefaultLayout>
      <TokenLogin />
    </DefaultLayout>
  </Page>
);
