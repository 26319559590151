import Ajv from 'ajv';
import zxcvbn from 'zxcvbn';

const ajv = new Ajv({
  allErrors: true,
  coerceTypes: 'string',
  $data: true,
  format: 'full',
});

export const getPasswordScore = password => zxcvbn(password || '').score;

ajv.addKeyword('isNotEmpty', {
  errors: true,
  type: 'string',
  validate: function validateIsNotEmpty(schema, value) {
    validateIsNotEmpty.errors = [
      {
        keyword: 'isNotEmpty',
        message: 'Required',
        params: { keyword: 'isNotEmpty' },
      },
    ];
    return typeof value === 'string' && value.trim() !== '';
  },
});

ajv.addKeyword('meetsPasswordRequirement', {
  type: 'string',
  errors: true,
  validate: function validatePasswordReq(schema, password) {
    validatePasswordReq.errors = [
      {
        keyword: 'meetsPasswordRequirement',
        message:
          'Your password is not strong enough. It must have at least 4 bars.',
        params: { keyword: 'meetsPasswordRequirement' },
      },
    ];
    return [
      getPasswordScore(password) >= 3, // dropbox zxcvbn
    ].every(requirement => !!requirement);
  },
});

const translateMessages = error => {
  switch (error.schemaPath) {
    case '#/properties/email/format':
      return 'Required format: email@example.com';
    case '#/properties/legalAccepted/const':
      return 'Required';
    case '#/properties/password/minLength':
      return 'Must be at least 1 character';
    case '#/properties/passwordConfirm/const':
      return 'This must match New Password';
    case '#/properties/termsAccepted/const':
      return 'Required';
    default:
      return error.message;
  }
};

export const AjvUtil = {
  compileSchema(ajvSchema) {
    return ajv.compile(ajvSchema);
  },
  /**
   * Need to deal with following error messages with array items
   *
   *   // let vObj = {
    //   name: "Required",
    //   testers: [ ce
    //     undefined,
    //     {
    //       name: 'Jip'
    //     }
    //   ],
    // };
   // return vObj;
   */
  formatValidationForFinalForm: (compiledAjvSchema, data) => {
    /**
     * React Final Form is expecting undefined if form is valid
     */
    if (!compiledAjvSchema || compiledAjvSchema(data)) {
      return undefined;
    }

    return AjvUtil.formatValidationErrorsForFinalForm(compiledAjvSchema.errors);
  },
  formatValidationErrorsForFinalForm: errors =>
    errors.reduce((returnObj, error) => {
      const dataPath = error.dataPath.replace('.', '');
      if (error.keyword === 'required') {
        const path =
          dataPath.length > 0
            ? `${dataPath}.${error.params.missingProperty}`
            : error.params.missingProperty;
        returnObj[path] = 'Required';
      } else {
        returnObj[dataPath] = translateMessages(error);
      }
      return returnObj;
    }, {}),
};
