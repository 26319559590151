const storage = window.localStorage;

/**
 * All the try/catch are for the very rare
 * browsers that don't do localStorage
 * */
export const LocalStorage = (id, defaultValue) => ({
  get() {
    try {
      const raw = storage.getItem(id);
      return raw ? JSON.parse(raw) : defaultValue;
    } catch (e) {
      return defaultValue;
    }
  },

  set(value = null) {
    if (value === null || value === '') {
      this.clear();
    } else {
      try {
        value = JSON.stringify(value);
        storage.setItem(id, value);
      } catch (e) {
        // silently fail
      }
    }
  },

  clear() {
    try {
      storage.removeItem(id);
    } catch (e) {
      // silently fail
    }
  },
});
