import React from 'react';

import { colors } from '../theme/axiom-theme';

export const AttrsHelper = {
  loremIpsum() {
    return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque eleifend. Sollicitudin nibh sit amet commodo. Imperdiet massa tincidunt nunc pulvinar sapien et. Quam vulputate dignissim suspendisse in est ante in nibh mauris. Ac tincidunt vitae semper quis. Ut tellus elementum sagittis vitae et leo duis ut. In hac habitasse platea dictumst vestibulum rhoncus. Gravida quis blandit turpis cursus in hac habitasse platea. Id donec ultrices tincidunt arcu. Eu feugiat pretium nibh ipsum consequat nisl vel pretium. Eu scelerisque felis imperdiet proin. Sit amet est placerat in egestas. Iaculis nunc sed augue lacus viverra. Commodo quis imperdiet massa tincidunt nunc. Elit duis tristique sollicitudin nibh sit. Ornare massa eget egestas purus viverra accumsan. Volutpat odio facilisis mauris sit amet massa vitae tortor. Nisi vitae suscipit tellus mauris a diam maecenas sed enim.';
  },
  childrenLoremIpsum(paragraphs = 1) {
    return (
      <>
        {new Array(paragraphs).fill(AttrsHelper.loremIpsum()).map((para, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <p key={i}>{para}</p>
        ))}
      </>
    );
  },
  // Cleans up className values for readability
  formatClassname(...className: Array<unknown>) {
    return [...new Set(className.filter(Boolean))].join(' ');
  },
  replaceColorNameWithHex(cssString?: string) {
    let formattedCssString = cssString;
    if (formattedCssString) {
      Object.keys(colors).forEach((colorName: keyof typeof colors) => {
        formattedCssString = formattedCssString.replace(
          colorName,
          colors[colorName].toLowerCase()
        );
      });
    }
    return formattedCssString;
  },
};
